.c-link {
    font-weight: 400;
    font-size: 28px;
    line-height: 1.4;
    text-decoration: none;
    color: $color-black-v2;
    transition: $global-transition;

    @include bp-down(medium) {
        font-size: 22px;
        line-height: 1.1;
    }

    &:hover {
        color: $color-brand-4;
    }
}
