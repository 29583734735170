/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1 {
    font-size: 2.25rem;
}

h2 {
    font-size: 1.75rem;
}

h3 {
    font-size: 1.5rem;
}

h4 {
    font-size: 1.25rem;
}

h5 {
    font-size: 1.125rem;
}

h6 {
    font-size: 1rem;
}

.c-heading {
    font-weight: 600;
    font-size: 40px;
    line-height: 1.2;

    @include bp-down(medium) {
        font-size: 28px;
        line-height: 1.14;
    }

    &--big {
        font-size: 80px;
        letter-spacing: -2px;

        @include bp-down(xlarge) {
            font-size: 50px;
        }

        @include bp-down(medium) {
            font-size: 36px;
            line-height: 1.1;
            letter-spacing: normal;
        }
    }

    &--middle {
        font-size: 24px;

        @include bp-down(medium) {
            font-size: 20px;
            font-weight: 500;
        }
    }

    &--color {
        &-pink {
            color: $color-brand-4;
        }

        &-purple {
            color: $color-primary-600;
        }

        &-purple-2 {
            color: $color-brand-22;
        }

        &-white {
            color: $color-text-white;
        }

        &-black {
            color: $color-black;
        }

        &-middle-black {
            color: $color-black-v2;
        }
    }
}
