.c-gradient-text {
    font-family: $font-name;
    font-style: normal;
    font-weight: 600;
    font-size: 240px;
    line-height: 0.8;
    background: linear-gradient(105.19deg, #8247ff 13.25%, #070007 83.58%);
    background-clip: text;
    -webkit-text-fill-color: transparent;

    @include bp-down(medium) {
        font-size: 180px;
    }
}
