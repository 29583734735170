.b-services {
    overflow: hidden;
    padding-bottom: 31px;

    @include bp(large) {
        padding-bottom: 94px;
    }

    &__title {
        margin-bottom: 40px;

        @include bp-down(medium) {
            margin-bottom: 24px;
        }
    }

    &__text {
        font-style: normal;
        font-weight: 600;
        // color: $color-white;
        color: $color-custom-1;
        // font-size: em(41);
        font-size: 24px;
        line-height: 1.25;
        text-align: left;
        margin-bottom: 111px;
        z-index: 2;
        position: relative;
        padding: 0;
        max-width: 700px;

        // @include bp(large) {
        // 	font-size: em(60);
        // 	line-height: 1.37;
        // 	max-width: 80%;
        // 	padding: 0;
        // 	margin-bottom: 166px;
        // }
        @include bp-down(medium) {
            font-size: 22px;
            font-weight: 500;
            line-height: 1.18;
            margin-bottom: 48px;
        }
    }

    &__list {
        color: $color-white;
    }

    &__list-item {
        width: 100%;
        margin: 0 0 64px 0;

        &:last-child {
            margin: 0;
        }

        @include bp(large) {
            margin: 0;
            width: calc(100% / 3);
        }
    }

    &__list-item-number {
        font-style: normal;
        font-weight: 600;
        // font-size: em(200);
        font-size: 240px;
        line-height: 1;
        // opacity: 0.9;
        margin: 0 0 20px 0;
        text-align: left;

        @include bp(large) {
            margin: 0 0 30px 0;
            font-size: 240px;
            text-align: center;
        }

        @include bp-down(medium) {
            font-size: 180px;
        }

        &--1 {
            background: linear-gradient(105.19deg, #ff4b61 13.25%, #070007 83.58%);
            background-clip: text;
            -webkit-text-fill-color: transparent;
            // background: linear-gradient(116.34deg, #e8465a 4.52%, rgba(7, 0, 7, 0.51) 95%);
            // background-clip: text;
            // -webkit-text-fill-color: transparent;
        }

        &--2 {
            background: linear-gradient(
                105.19deg,
                #b11590 8.25%,
                rgba(255, 255, 255, 0.1) 63.58%,
                rgba(7, 0, 7, 0.2) 92.58%
            );
            background-clip: text;
            -webkit-text-fill-color: transparent;
            // background: linear-gradient(107.67deg, #b11590 6.89%, rgba(255, 255, 255, 0) 95%);
            transition: $global-transition;

            @include bp(large) {
                // margin-top: 180px;
            }

            &.active {
                margin-top: 0;
            }
        }

        &--3 {
            // background: linear-gradient(105.65deg, #9a79b4 6.07%, rgba(101, 101, 101, 0) 95%);
            background: linear-gradient(105.19deg, #9a79b4 13.25%, rgba(101, 101, 101, 0) 83.58%);
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    &__list-item-title {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 1.47;
        // color: $color-white;
        color: $color-custom-1;
        opacity: 0.9;
        text-align: left;
        margin: 0 0 16px 0;

        @include bp(large) {
            margin: 0 0 24px 0;
            // font-size: em(36);
            font-size: 24px;
            line-height: 1.25;
            // padding-right: vw(260);
        }

        @include bp-down(large) {
            font-size: 20px;
            font-weight: 500;
            line-height: 1.2;

            br {
                display: none;
            }
        }
    }

    &__list-item-text {
        font-style: normal;
        // font-size: em(24);
        font-size: 16px;
        line-height: 1.62;
        // color: $color-white;
        color: $color-custom-1;
        opacity: 0.9;
        margin-bottom: 32px;
        text-align: left;
        padding: 0;

        @include bp(large) {
            margin-bottom: 48px;
            padding: 0 vw(149) 0 0;
            font-weight: 400;
            // font-size: em(26);
            font-size: 18px;
            line-height: 1.67;
        }
    }

    &__list-item-button {
        min-width: 161px;
    }

    &__button {
        margin: 48px 0 0 0;

        @include bp(large) {
            margin: 64px 0 0 0;
        }
    }
}
