.b-heading-featured {
    &--spaces {
        padding-top: 200px;
        padding-bottom: 24px;

        @include bp-down(large) {
            padding-top: 100px;
        }
    }

    &__heading {
        font-style: normal;
        font-weight: 600;
        font-size: 80px;
        line-height: 1.2;
        text-align: center;
        letter-spacing: -2px;
        margin: 0 0 40px 0;
        background: linear-gradient(
            105.19deg,
            var(--heading-featured-gradient-start) 13.25%,
            var(--heading-featured-gradient-end) 83.58%
        );
        background-clip: text;
        -webkit-text-fill-color: transparent;
        // text-fill-color: transparent;

        @include bp-down(large) {
            font-size: 36px;
            line-height: 1.1;
            margin-bottom: 20px;
        }
    }

    &__text {
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 1.4;
            text-align: center;
            color: $color-custom-2;
            margin-bottom: 16px;

            @include bp-down(large) {
                font-size: 16px;
            }
        }
    }

    &__line {
        margin-top: 24px;
        width: 1px;
        height: 120px;
        background-color: var(--heading-featured-line);
    }
}
