.b-contact-content-form {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: var(--contact-content-form-bg);

    @include bp-down(large) {
        padding-top: 32px;
        padding-bottom: 32px;
    }

    &__wrapper {
        gap: 48px 48px;

        --wrapper: 1130px;
    }

    &__content {
        @include bp(large) {
            max-width: 533px;
        }
    }

    &__title {
        color: var(--contact-content-form-title-color, #{$color-brand-33});
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 1.2;
        margin: 0 0 40px 0;

        @include bp-down(large) {
            order: 2;
            font-size: 28px;
            line-height: 1.14;
            margin: 0 0 24px 0;
        }
    }

    &__text {
        color: var(--contact-content-form-text-color, #{$color-black-v2});
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.67;

        @include bp-down(large) {
            order: 3;
            font-size: 16px;
            line-height: 1.62;
        }

        strong {
            font-weight: 700;
        }

        p {
            margin-bottom: 5px;
        }

        ul {
            list-style: none;
            padding: 0 0;
            margin: 0;

            > li {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                margin: 0;

                &::before {
                    content: '\2022';
                    display: block;
                    flex-direction: row;
                    padding: 0 15px;
                }
            }
        }
    }

    &__image {
        margin: 80px 0 0 0;

        @include bp-down(large) {
            margin: 0 0 48px 0;
            order: 1;
        }

        img {
            width: 100%;
            border-radius: 8px;
        }
    }

    &__form {
        @include bp(large) {
            max-width: 437px;
        }
    }

    &--down {
        .c-form {
            &__btn-wrapper {
                &-inner {
                    &::after {
                        background: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 3.56689V17.3169' stroke='%23FBFBFB' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4.875 11.6919L10.5 17.3169L16.125 11.6919' stroke='%23FBFBFB' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
                            no-repeat center center;
                    }

                    &:hover {
                        &::after {
                            background: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 3.56689V17.3169' stroke='%230A0A0A' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4.875 11.6919L10.5 17.3169L16.125 11.6919' stroke='%230A0A0A' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
                                no-repeat center center;
                        }
                    }
                }
            }
        }
    }
}
