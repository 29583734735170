.c-accordion {
    $self: &;

    &__item {
        overflow: hidden;
        margin: 0 0 24px;
        border: none;
        transition: $global-transition;
        border-bottom: 1px solid #959596;

        &:hover {
            border-bottom-color: $color-brand-2;
        }

        @include bp-down(medium) {
            margin: 0 0 16px;
        }
    }

    &__btn {
        position: relative;
        display: block;
        width: 100%;
        font-family: $font-name;
        font-size: 20px;
        font-weight: 600;
        color: $color-black-v2;
        padding: 16px 20px 16px 0;
        background-color: transparent;
        border: none;
        text-align: left;
        outline: none;
        cursor: pointer;
        transition: $global-transition;

        &:focus,
        &:active,
        &:hover {
            outline: none;
            border: none;
            box-shadow: none;
        }

        &:hover {
            opacity: 0.8;
        }

        &.active {
            #{$self}__icon-arrow {
                transform: translateY(-50%) rotate(180deg);
            }

            svg .line-second {
                opacity: 0;
            }
        }
    }

    &__icon-arrow {
        position: absolute;
        top: 50%;
        right: 0;
        line-height: 1;
        transform: translateY(-50%);
        transition: all 0.3s linear;
        transform-origin: center;
    }

    &__btn-text {
        display: inline-block;
        vertical-align: middle;
    }

    &__description {
        transition: height 0.3s linear;
        overflow: hidden;

        a {
            text-decoration: underline;
        }

        &:not(.is-active) {
            display: none;
        }
    }

    &__text {
        padding: 16px 0 0;

        @include bp-down(medium) {
            padding: 10px 0 0;
        }

        ul,
        ol {
            padding-left: 20px;
        }
    }
}
