/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */

.c-post {
    $self: &;

    padding: 120px 0 80px;

    --main-width: 60%;
    --gap-size: 112px;

    @include bp-down(medium) {
        padding: 70px 0 40px;
    }

    &__wrapper {
        gap: 0 var(--gap-size);
        max-width: 1170px;
    }

    &__related-podcast {
        margin: 100px 0;

        @include bp-down(medium) {
            margin: 60px 0;
        }
    }

    &__main {
        width: var(--main-width);
        order: 2;

        @include bp-down(large) {
            order: 3;
            width: 100%;
        }
    }

    &__mobile-nav-wrap {
        display: none;

        @include bp-down(large) {
            display: block;
            margin: 32px 0;
        }
    }

    &__tags {
        gap: 8px;
        margin: 24px 0;

        @include bp-down(medium) {
            margin: 20px 0;
        }
    }
    /* stylelint-disable no-descending-specificity */

    &__sidebar {
        width: calc(100% - var(--main-width) - var(--gap-size));
        position: sticky;
        top: 130px;
        order: 3;

        @include bp-down(large) {
            display: none;

            // order: 2;
            // width: 100%;
            // position: static;
            // margin-bottom: em(40);
        }

        &-nav {
            list-style: none;
            margin: 0;
            padding: 40px 24px 28px;
            background: $color-custom-1;
            border: none;
            box-shadow: 0 15px 30px rgba(51, 37, 112, 0.08);
            border-radius: 8px;

            @include bp-down(large) {
                padding: 16px 24px 10px;
            }

            li {
                &:last-child {
                    a {
                        border-bottom: 0;
                    }
                }

                a {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 1.5;
                    padding: 12px 0 12px 5px;
                    color: $color-custom-2;
                    text-decoration: none;
                    border-bottom: 1px solid $border-gray-color;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    transition: $global-transition;

                    @include bp-down(large) {
                        font-size: 14px;
                    }

                    &::before {
                        content: '\2022';
                        color: $color-custom-2;
                        font-weight: bold;
                        display: inline-block;
                        width: 1em;
                        margin-left: 0;
                        padding-top: 5px;
                        transition: $global-transition;
                    }

                    &:hover {
                        @include bp(large) {
                            color: $color-brand-22;
                            text-shadow: 0 0 1px $color-brand-22;

                            &::before {
                                color: $color-brand-22;
                            }
                        }
                    }
                }
            }
        }
    }
    /* stylelint-enable no-descending-specificity */

    &__sidebar-title {
        font-weight: 600;
        font-size: em(26);
        line-height: 1.54;
        color: $color-brand-3;
        text-transform: uppercase;
        margin: 0 0 em(23) 0;
    }

    &__heading {
        // padding: em(150) 0 0 0;

        width: 100%;
        order: 1;

        // @include bp(large) {
        // 	padding: em(350) 0 0 0;
        // }

        &--order {
            @include bp-down(large) {
                display: flex;
                flex-direction: column;
            }

            #{$self}__podcast-mobile {
                order: 1;
            }

            h1 {
                order: 2;
            }

            div {
                order: 3;
            }
        }
    }

    &__page {
        font-style: normal;
        font-weight: 600;
        font-size: em(24);
        line-height: 2.71;
        letter-spacing: 0.62em;
        color: $color-brand-22;
        text-transform: uppercase;
        text-align: left;
        display: block;
    }

    &__title {
        margin-bottom: 68px;

        @include bp-down(medium) {
            margin-bottom: 64px;
        }
    }

    &__thumb {
        border-radius: 8px;

        img {
            width: 100%;
            border-radius: 8px;
        }
    }

    &__share {
        padding: 24px;
        margin-bottom: 0;
        border-bottom: none;
        font-weight: 400;
        font-size: 20px;
        line-height: 1.4;
        color: $color-black;

        &--mobile {
            display: none;
        }

        @include bp-down(large) {
            padding: 0;
            margin-top: 24px;
            font-size: 18px;

            &--desktop {
                display: none;
            }

            &--mobile {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
            }
        }

        &-title {
            margin-bottom: 12px;

            @include bp-down(large) {
                margin: 0 24px 0 0;
            }
        }

        &--bottom {
            text-transform: none;
            border-bottom: 0;
            margin: 0;

            @include bp(large) {
                margin: 0;
            }
        }
    }

    &__bottom {
        padding: 0;
        margin: 20px 0;
        border-bottom: none;

        @include bp-down(medium) {
            margin: 32px 0;
        }
    }

    &__wrap-authors {
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__author {
        display: block;
        width: 49%;
        margin-bottom: 24px;

        @include bp-down(medium) {
            width: 100%;

            &:last-child {
                margin-bottom: 0;
            }
        }

        img {
            width: 120px;
            height: 120px;
            object-fit: cover;
            object-position: center top;
            margin: 0 12px 0 0;
            border-radius: 8px;

            @include bp-down(medium) {
                width: 106px;
                height: 106px;
            }
        }

        &-label {
            font-weight: 400;
            font-size: 14px;
            letter-spacing: 1.57;
            color: #866aff;
            margin: 0 0 4px 0;
        }

        &-name {
            font-weight: 400;
            font-size: 16px;
            line-height: 1.62;
            color: $color-black;
            margin: 0 0 4px 0;
        }

        &-job {
            font-weight: 400;
            font-size: 14px;
            line-height: 1.57;
            color: $color-custom-2;
        }
    }
    /* stylelint-disable no-descending-specificity */
    &__content {
        $heading-base: 37;

        margin-top: 96px;
        color: $color-custom-2;

        @include bp-down(large) {
            margin-top: 48px;
        }

        @for $i from 1 through 5 {
            h#{$i} {
                margin-bottom: 20px;
                font-size: em($heading-base);
                color: $color-black-v2;

                @include bp-down(medium) {
                    font-size: em($heading-base - 2);
                    font-weight: 500;
                }
            }
            $heading-base: $heading-base - 5;
        }

        p {
            margin-bottom: 40px;
            color: $color-custom-2;

            @include bp-down(medium) {
                margin-bottom: 20px;
            }
        }

        blockquote {
            quotes: none;
            background: $color-white;
            box-shadow: 0 15px 30px rgba(51, 37, 112, 0.08);
            border-radius: 8px;
            padding: 40px;
            font-weight: 400;
            text-align: left;
            font-style: normal;
            color: $color-custom-2;
            margin: 64px 0;

            @include bp-down(medium) {
                margin: 32px 0;
                padding: 16px;
            }

            p {
                margin: 0;
            }
        }

        img {
            &:not([class*='wp-image-']) {
                margin: 0;
            }

            border-radius: 8px;
        }

        ul:not(.c-custom-list),
        ol {
            margin-left: 30px;

            @include bp-down(medium) {
                margin-left: 0;
                list-style-type: none;
            }

            li {
                margin: 0 0 40px 0;
                font-style: normal;
                font-size: 18px;
                line-height: 1.67;

                &::marker {
                    font-size: 8px;
                }

                @include bp-down(medium) {
                    font-size: 16px;
                    margin-bottom: 20px;
                }
            }
        }

        a:not(.c-btn, .c-custom-list__item-link) {
            color: $color-medium-grey;
            transition: $global-transition;

            &:hover {
                color: $color-brand-22;
            }
        }

        strong {
            font-weight: 600;
        }

        .wp-block-image {
            margin: 64px 0;

            @include bp-down(medium) {
                margin: 32px 0;
            }

            &,
            .size-full {
                &,
                img {
                    width: 100%;
                }
            }
        }
    }

    &__text-under-title {
        color: $color-black-v2;
    }

    &__podcast-mobile {
        display: none;

        @include bp-down(large) {
            order: 1;
            display: block;
            margin: 0 0 24px;
        }
    }

    /* other type single */

    &--type-podcast {
        @include bp-down(medium) {
            padding: 30px 0 40px;
        }

        #{$self}__wrapper {
            max-width: 1600px;

            @media (max-width: 1600px) {
                max-width: 100%;
            }
        }
        #{$self}__sidebar {
            width: calc(50% - var(--gap-size));

            @include bp-down(large) {
                display: none;
            }
        }
        #{$self}__main {
            width: 50%;
            padding-left: 10%;

            @include bp-down(xlarge) {
                padding-left: 0;
            }

            @include bp-down(large) {
                width: 100%;
                padding: 0;
            }
        }

        #{$self}__title {
            margin-bottom: 24px;
        }
        #{$self}__content {
            margin-top: 120px;

            @include bp-down(large) {
                margin-top: 64px;
            }
        }
    }
}
/* stylelint-enable no-descending-specificity */
.alignnone {
    margin: 1em 1em 1em 0;
}

.aligncenter {
    margin: 1em auto;
    display: block;
}

.alignright {
    float: right;
    margin: 1em 0 1em 1em;
}

.alignleft {
    float: left;
    margin: 1em 1em 1em 0;
}

.wp-caption,
.wp-element-caption {
    text-align: center;
    max-width: 100%;
}

.wp-caption-text,
.wp-element-caption {
    font-style: italic;
    margin-top: 0.5em;
    font-size: 14px;
}
