.b-contact-no-form {
    padding: em(80) 0;
    max-width: 1270px;
    margin: 0 auto;

    &__title {
        margin-bottom: 32px;
    }

    &__content {
        padding: 0;
        width: 50%;

        @include bp-down(large) {
            width: 100%;
        }

        @include bp(medium) {
            padding: 0 vw(120) 0 0;
        }

        @include bp(xlarge) {
            padding: 0 vw(30) 0 0;
        }

        p {
            font-style: normal;
            font-weight: 400;
            color: $color-brand-23;
            font-size: em(22);
            line-height: 1.91;
            margin: 0 0 5px 0;
        }

        a {
            font-style: normal;
            color: $color-black-v2;
            font-size: 22px;
            font-weight: 400;
            line-height: 1.17;
            margin: 0 0 40px 0;
            // opacity: 0.9;
            cursor: pointer;
            text-decoration: none;

            svg {
                width: 24px;
                height: 24px;
            }

            span {
                padding-left: em(6.65);
            }
        }
    }

    &__text-mobile {
        display: none;

        @include bp-down(large) {
            display: block;
        }
    }

    &__text-desktop {
        @include bp-down(large) {
            display: none;
        }
    }

    &__team {
        gap: 24px;
        width: 50%;

        @include bp-down(large) {
            width: 100%;
        }

        @include bp-down(small) {
            gap: 11px;
        }
    }

    &__person {
        // background: $color-white;
        // box-shadow: 0 23px 38px rgba($color-brand-18, 0.11);
        // border-radius: 8px;
        // padding: 55px 27px 44px 27px;
        // text-align: center;
        // flex: auto;

        // @include bp(large) {
        // 	width: 100%;
        // 	flex: 50%;
        // }

        // &--single {
        // 	max-width: 380px;
        // }
    }

    &__person-thumb {
        margin: 0 auto 22px auto;
        padding: 8px;
        background-color: $color-white;
        box-shadow: 0 1px 24px rgba($color-black, 0.05);
        width: 160px;
        height: 160px;
        border-radius: 50%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    &__person-name {
        font-style: normal;
        font-weight: 600;
        font-size: em(24);
        line-height: 1.54;
        color: $color-brand-3;
        opacity: 0.9;
        margin: 0 0 10px 0;
    }

    &__person-data {
        font-style: normal;
        font-weight: 400;
        font-size: em(20);
        line-height: 1.6;
        color: $color-brand-24;
    }
}
