.b-hero {
    position: relative;

    &__wrapper {
        @include bp(large) {
            min-height: calc(100vh - 112px);
        }

        &--no-content,
        &--media {
            @include bp(large) {
                height: auto;
            }
        }

        &--disable-center {
            height: auto;
            padding-top: 80px;
            padding-bottom: 80px;
            min-height: calc(100vh - 112px);

            @include bp-down(large) {
                padding-top: 0;
                padding-bottom: 0;
            }

            @include bp-down(medium) {
                min-height: auto;
            }
        }
    }

    &__main {
        width: 100%;
        padding: 0;
        padding: 30px 0 0 0;

        @include bp-down(large) {
            padding: 64px 0 0;
        }

        &--one {
            text-align: center;
            // padding: em(211) 0 0 0;
            // padding: em(105) 0 0 0;
            padding: 30px 0 0 0;

            @include bp(large) {
                text-align: center;
                // padding: em(164) 0 0 0;
                // padding: em(132) 0 0 0;
                // padding: 0;
            }

            @include bp-down(large) {
                padding: 120px 0 0;
            }

            @include bp-down(medium) {
                padding: 64px 0 0;
            }
        }

        &--two {
            text-align: left;

            @include bp(large) {
                text-align: center;
            }
        }

        &--media {
            // padding: em(190) 0 0 0;
            padding: 64px 0 0 0;

            @include bp(large) {
                // padding: em(250) 0 0 0;
                padding: 80px 0 0 0;
            }
        }

        &--no-content {
            padding: em(190) 0 em(100) 0;

            @include bp(large) {
                padding: em(348) 0 em(256) 0;
            }
        }
    }

    &__bottom {
        width: 100%;
        padding: 0 0 em(30) 0;
        margin-top: auto;

        &--media {
            position: absolute;
            bottom: 0;
            padding: 0 #{$wrapper-gutter + 'px'} 30px #{$wrapper-gutter + 'px'};
            max-width: #{$wrapper} + 'px';
        }
    }

    &__slogan {
        font-style: normal;
        font-weight: 600;
        letter-spacing: 0.62em;
        // font-size: em(24);
        // line-height: 2.71;
        font-size: 20px;
        line-height: 1.2;
        color: $color-brand-22;
        // text-align: center;
        text-transform: uppercase;
        margin: 0 0 12px 0;

        @include bp-down(medium) {
            font-size: 16px;
            margin: 0 0 8px 0;
        }
    }

    &__heading {
        font-style: normal;
        font-weight: 600;
        // text-align: center;
        color: $color-brand-3;
        opacity: 0.9;
        margin: 0;

        &--dark {
            color: $color-white;
        }

        &--small {
            font-size: em(54);
            line-height: 1.35;

            @include bp(large) {
                font-size: em(80);
                line-height: 1.15;
            }

            @include bp-down(medium) {
                font-size: 36px;
                line-height: 1.1;
            }
        }

        &--bigger {
            font-size: 36px;
            line-height: 1.11;
            opacity: 0.9;

            @include bp(large) {
                // max-width: 61.9%;
                max-width: 65.9%;
                margin: 0 auto;
                opacity: 1;
                letter-spacing: -2px;
                // font-size: em(94);
                // line-height: 1.15;
                font-size: 80px;
                line-height: 1.2;
            }
        }
    }

    &__content {
        font-style: normal;
        font-weight: 400;
        // color: $color-brand-24;
        color: $color-custom-2;

        &--two {
            // font-size: em(30);
            // line-height: 1.67;
            font-size: 28px;
            line-height: 1.4;
            padding: em(11.73) 0 em(21.33) 0;

            @include bp(large) {
                padding: 32px 0 103px 0;
                // font-size: em(36);
                // line-height: 1.61;
                max-width: 66.67%;
                margin-left: auto;
                margin-right: auto;
            }

            @include bp-down(medium) {
                font-size: 16px;
                padding: 20px 0 48px 0;
            }
        }

        &--one {
            padding: em(30) 0;
            font-size: em(18);
            line-height: calc((26 * 1) / 18);
            text-align: center;

            &::before {
                content: '';
                // height: 64px;
                height: 80px;
                width: 1px;
                background-color: $color-white;
                display: block;
                margin: em(28) auto 0 auto;
            }

            @include bp(large) {
                padding: em(30) 0 0 0;
            }
        }

        &--line-below {
            &::before {
                display: none;
            }

            &::after {
                content: '';
                height: 80px;
                width: 1px;
                background-color: $color-white;
                display: block;
                margin: 32px auto 0 auto;

                @include bp-down(medium) {
                    margin-top: 40px;
                    height: 64px;
                }
            }
        }

        &--line-big {
            &::after {
                margin-top: 80px;
                height: 120px;

                @include bp-down(large) {
                    display: none;
                }
            }
        }

        &--bigger-top-space {
            padding-top: 120px;

            @include bp-down(medium) {
                padding-top: 80px;
            }
        }

        &--dark {
            color: $color-white;
        }

        &--light {
            &::after {
                background-color: $color-brand-22;
            }
        }

        &--smaller-paddign {
            padding: 32px 0;

            @include bp-down(medium) {
                padding: 20px 0;
            }
        }

        img {
            margin: 0;
        }

        p {
            // margin: 0 0 em(13) 0;
            margin-bottom: 16px;
            font-size: inherit;

            &:last-child {
                margin: 0;
            }
        }

        &-wrapper-btn {
            display: none;
            text-align: center;

            &--desktop {
                display: block;
                margin-top: 50px;

                @include bp-down(large) {
                    display: none;
                }
            }

            @include bp-down(medium) {
                display: block;
                margin-top: 40px;

                &--desktop {
                    display: none;
                }
            }
        }
    }

    &__subscribe-wrapper {
        margin-top: 80px;

        @include bp-down(large) {
            margin-top: 32px;
        }
    }

    &__additional-text {
        opacity: 0.9;
        margin-top: 40px;

        @include bp-down(medium) {
            margin-top: 20px;
        }
    }

    &__media {
        border-radius: 8px;
        overflow: hidden;
        font-size: 0;

        @include bp(large) {
            max-width: 36.25%;

            &--wider {
                max-width: 90%;
            }
        }
    }

    // &__link {
    // 	text-transform: uppercase;
    // }

    &__contact,
    &__link {
        font-weight: 400;
        // font-size: em(16);
        font-size: 20px;
        color: $color-white;
        text-decoration: none;

        // @include bp(large) {
        // 	font-size: em(20);
        // }
        @include bp-down(medium) {
            font-size: 16px;
        }

        &--light {
            color: $color-brand-3;
        }
    }

    /* Line hero bottom page */

    &--line-bottom {
        position: relative;

        @include bp(large) {
            margin-bottom: 80px;
        }

        .b-hero__content--line-below::after {
            position: absolute;
            bottom: -40px;
            left: calc(50% - 1px);
            transform: translate(0, 50%);

            @include bp-down(large) {
                position: relative;
                left: auto;
                transform: none;
                bottom: 0;
            }

            @include bp(full) {
                bottom: 0;
            }
        }
    }

    &--smaller-height {
        .b-hero__wrapper {
            @include bp(large) {
                min-height: calc(100vh - 157px);
            }
        }
    }
}
