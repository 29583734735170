body {
    min-height: 100%;
    background-color: var(--background);
    padding-top: 114px;
    scroll-behavior: smooth;

    @include bp-down(large) {
        padding-top: 72px;
    }
}
