.l-podcasts {
    font-style: normal;
    padding: 90px 0 120px 0;

    @include bp-down(medium) {
        padding: 64px 0;
    }

    &__title {
        margin-bottom: 40px;

        @include bp-down(medium) {
            margin-bottom: 20px;
        }
    }

    &__list {
        max-width: 90%;
        margin: 0 auto;

        @media (max-width: 1445px) {
            max-width: 1130px;
        }

        &-item {
            margin-bottom: 64px;

            @include bp-down(medium) {
                margin-bottom: 40px;
            }
        }
    }

    &__pagination-wrap {
        margin-top: 30px;

        @include bp-down(medium) {
            margin-top: 0;
        }
    }
}
