/* stylelint-disable no-descending-specificity */
.b-posts-slide {
    font-style: normal;
    padding: 50px 0;

    --textColorMain: $color-text-white;

    @include bp(large) {
        padding: em(135) 0 em(100) 0;
    }

    a {
        text-decoration: none;
    }

    img {
        // $img-height: 492px;

        width: 100%;
        // height: calc($img-height / 2);
        object-fit: cover;
        object-position: center top;

        // @include bp(large) {
        // 	height: $img-height;
        // }
    }

    &__image {
        height: 246px;
        border-radius: 8px;

        @include bp-down(small) {
            height: 213px;
        }
    }

    &__button-wrap {
        margin-top: 64px;

        &--small-margin {
            margin-top: 0;
        }

        @include bp-down(medium) {
            margin-top: 40px;

            &--small-margin {
                margin-top: 0;
            }
        }
    }

    &,
    a {
        color: $color-white;
    }

    &--dark {
        --textColorMain: $color-black-v2;

        &,
        a {
            color: $color-black;
        }
    }

    &__heading {
        font-style: normal;
        // font-weight: 600;
        // text-align: center;
        // font-size: em(50);
        // line-height: 1.44;
        // padding: 0;
        margin: 0 0 48px 0;

        // @include bp(large) {
        // 	font-size: em(94);
        // 	line-height: 1.15;
        // 	margin: 0 auto em(13.8) auto;
        // }
    }

    &__title {
        // font-weight: 700;
        // font-size: em(30);
        // line-height: 1.47;
        // opacity: 0.9;
        margin: 40px 0 12px;
        font-size: 24px;
        font-weight: 600;
        line-height: 1.25;
        color: var(--textColorMain);

        @include bp-down(small) {
            font-size: 20px;
            line-height: 1.2;
            margin: 20px 0 8px 0;
        }

        // @include bp(large) {
        // 	font-size: em(36);
        // 	line-height: 1.22;
        // 	margin: em(19.55) 0 em(10.66) 0;
        // 	padding: 0 15% 0 0;
        // }
    }

    &__subtitle {
        margin-bottom: 32px;
    }

    &__wrapper-simple-grid {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 24px;

        @include bp-down(medium) {
            gap: 0;
        }
    }

    &__excerpt {
        // font-weight: 400;
        // font-size: em(23);
        font-size: 18px;
        line-height: 1.65;
        opacity: 0.9;
        margin: 0 0 47px 0;
        color: var(--textColorMain);

        @include bp-down(small) {
            font-size: 16px;
        }

        // @include bp(large) {
        // 	font-size: em(26);
        // 	line-height: 1.54;
        // 	margin: 0;
        // 	padding: 0 15% 0 0;
        // }
    }

    &__article {
        &--simple-grid {
            width: calc(33% - 14px);
            margin-bottom: 64px;

            @include bp-down(large) {
                width: calc(50% - 14px);
            }

            @include bp-down(medium) {
                margin-bottom: 64px;
                width: 100%;
            }
        }
    }

    &__wrapper {
        &.swiper-wrapper {
            // @include bp-down(large) {
            // 	flex-direction: column;
            // 	transform: translate3d(0, 0, 0) !important;

            // 	.swiper-slide {
            // 		display: none;
            // 	}

            // 	@for $i from 0 through 2 {
            // 		.swiper-slide[data-swiper-slide-index='#{$i}']:not(.swiper-slide-duplicate) {
            // 			display: block;
            // 			width: 100% !important;
            // 		}
            // 	}
            // }
        }
    }

    &__tag-wrapper {
        @include bp-down(small) {
            display: none;
        }
    }

    &__arrow-wrapper {
        @include bp-down(small) {
            display: none;
            margin-left: -16px;
        }
    }

    .o-wrapper {
        // @include bp-down(small) {
        // 	padding-right: 0;
        // }
    }
}
