/* ==========================================================================
   #PAGE
   ========================================================================== */

/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */

html {
    background: $color-bg;
    color: $color-text;
    font-family: $font-name;
    // font-size: 0.7125rem; /* [1] */ /* 13px */
    font-size: #{$browser-context * 0.73 + 'px'};
    line-height: 1.15; /* [1] */
    min-height: 100%; /* [3] */
    overflow-y: scroll; /* [2] */
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    scroll-behavior: smooth;
    scroll-padding-top: 90px;

    @include bp(medium) {
        // font-size: 0.775em; /* 14px */
        font-size: #{$browser-context * 0.73 + 'px'};
    }

    @include bp(large) {
        // font-size: 0.8375em; /* 15px */
        font-size: #{$browser-context * 0.83 + 'px'};
    }

    // @include bp(full) {
    // }

    @include bp(full) {
        font-size: #{$browser-context + 'px'};
    }
}
