/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

/* stylelint-disable  no-descending-specificity */
.c-btn {
    display: inline-block;

    /* [1] */
    vertical-align: middle;

    /* [2] */
    font: inherit;

    /* [3] */
    text-align: center;

    /* [4] */
    margin: 0;

    /* [4] */
    cursor: pointer;

    /* [5] */
    padding: 0.5rem 1rem;
    transition: $global-transition;
    border-radius: $global-radius;
    font-style: normal;
    text-decoration: none;
}

/* Style variants
   ========================================================================== */

/* stylelint-disable no-duplicate-selectors */

.c-btn--primary {
    background-color: $color-black-v2;
    border-radius: 99px;
    font-weight: 500;
    font-size: 16px;
    line-height: calc((26 * 1) / 18);
    text-align: center;
    color: $color-white;
    padding: em(9) em(21.5);
    border: none;
    border: 1px solid $color-black-v2;

    &:hover {
        // color: rgba($color-white, 0.9);
        // background-color: rgba($color-brand-3, 0.5);
        background-color: transparent;
        border-color: $color-black-v2;
        color: $color-black-v2;

        svg {
            path {
                stroke: $color-black-v2;
            }
        }
    }

    @include bp(large) {
        font-size: em(18);
    }
}

.c-btn--primary-dark {
    border-radius: 99px;
    font-weight: 500;
    font-size: 16px;
    line-height: calc((26 * 1) / 18);
    text-align: center;
    border: 1px solid $color-white;
    // color: $color-brand-3;
    color: $color-btn-v2;
    background-color: $color-white;
    padding: em(9) em(21.5);

    &:hover {
        // color: rgba($color-white, 0.9);
        // background-color: rgba($color-white, 0.5);
        color: $color-custom-1;
        background-color: transparent;
        border-color: $color-custom-1;
    }

    @include bp(large) {
        font-size: em(18);
    }
}

.c-btn--purple {
    background-color: $color-brand-22;
    border-radius: 300px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: calc((26 * 1) / 18);
    text-align: center;
    color: $color-white;
    opacity: 0.9;
    padding: em(9) em(21.5);

    &:hover,
    &:active,
    &:focus {
        color: $color-white;
        background-color: rgba($color-brand-22, 0.9);
    }

    @include bp(large) {
        font-size: em(18);
    }
}

.c-btn--transparent-dark {
    border-radius: 99px;
    font-weight: 500;
    font-size: 16px;
    line-height: calc((26 * 1) / 18);
    text-align: center;
    background-color: transparent;
    padding: 0;

    &,
    &:hover {
        color: $color-white;
    }

    @include bp(large) {
        font-size: em(18);
    }
}

.c-btn {
    &--transparent-border-dark {
        padding: 12px 25px;
        font-size: 16px;
        font-weight: 500;
        border-radius: 99px;
        line-height: 1.25;
        letter-spacing: 0.5px;
        background-color: transparent;
        border: 1px solid $color-black-v2;
        color: $color-black-v2;

        &:hover {
            color: $color-custom-1;
            border-color: $color-black-v2;
            background-color: $color-black-v2;

            svg {
                path {
                    stroke: $color-custom-1;
                }
            }
        }

        svg {
            path {
                stroke: $color-black-v2;
            }
        }
    }

    &--transparent-border-light {
        padding: 12px 25px;
        font-size: 16px;
        font-weight: 500;
        border-radius: 99px;
        line-height: 1.25;
        letter-spacing: 0.5px;
        background-color: transparent;
        border: 1px solid $color-custom-1;
        color: $color-custom-1;

        &:hover {
            color: $color-black-v2;
            background-color: $color-custom-1;
            border-color: $color-custom-1;
        }
    }
}

.c-btn-arrows {
    display: inline-flex;
    padding: 12px 25px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 99px;
    line-height: 1.25;
    letter-spacing: 0.5px;
    background-color: transparent;
    border: 1px solid $color-custom-1;
    color: $color-custom-1;

    svg,
    span {
        display: inline-block;
        vertical-align: middle;
        // transition: $global-transition;
    }

    svg {
        margin: 0 0 2px 8px;

        path {
            transition: $global-transition;
        }
    }

    &:hover {
        background-color: $color-custom-1;
        color: $color-black-v2;

        // span {
        // 	transform: translateX(14px);
        // }

        // svg {
        // 	opacity: 0;
        // }

        path {
            stroke: $color-black-v2;
        }
    }

    &--style {
        &-dark {
            color: $color-black-v2;
            border-color: $color-black-v2;

            svg path {
                stroke: $color-black-v2;
                transition: $global-transition;
            }

            &:hover {
                background-color: $color-black-v2;
                border-color: $color-black-v2;
                color: $color-white;

                svg path {
                    stroke: $color-white;
                }
            }
        }
    }
}

.c-btn--icon {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
        transition: $global-transition;
        margin-left: 18px;
    }

    &:hover {
        svg {
            transform: translateX(5px);
        }
    }
}

.c-btn--600 {
    font-weight: 600;
}

/* Size variants
   ========================================================================== */

.c-btn--small {
    padding: 5px em(24);

    &-v2 {
        padding: 14px 28px;
        font-size: 16px;
        line-height: 1.25;
        letter-spacing: 0.5px;
    }
}

.c-btn--large {
    padding: em(16) 0;
    font-size: em(28);
    line-height: calc((26 * 1) / 18);

    @include bp(medium) {
        padding: em(16);
        font-size: em(38);
        line-height: calc((52 * 1) / 38);
    }

    @include bp(large) {
        font-size: em(48);
        line-height: calc((52 * 1) / 48);
    }
}

/* Other type size/padding
   ========================================================================== */
.c-btn--v2 {
    padding: 14px 28px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.25;
    text-align: center;
    letter-spacing: 0.5px;
}

// Gap
.c-btn--gap-8 {
    gap: 8px;
}
