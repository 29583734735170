/* stylelint-disable selector-no-qualifying-type */
.b-aplication-box {
    position: relative;
    padding: 40px 0;

    @include bp-down(medium) {
        padding: 20px 0;
    }

    &__wrapper {
        padding: 0;
    }

    &__box {
        padding: 40px;
        border-radius: 8px;
        background: $color-custom-1;
        box-shadow: 0 15px 30px rgba(51, 37, 112, 0.08);

        @include bp-down(medium) {
            padding: 24px;
        }

        &-header {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 32px;

            @include bp-down(medium) {
                margin-bottom: 16px;
            }
        }

        img.b-aplication-box__box-img {
            width: 40px;
            height: auto;
            margin-right: 24px;
            display: none;

            @include bp-down(medium) {
                display: block;
            }
        }

        &-text-first {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-bottom: 16px;
            font-weight: 600;
            font-size: 20px;
            line-height: 1.2;
            color: rgba($color-black-v2, 0.9);

            @include bp-down(medium) {
                margin-bottom: 12px;
                font-size: 16px;
                line-height: 1.6;
            }
        }

        a.b-aplication-box__box-copy-email {
            color: #7f67e9;
            text-decoration: none;
            cursor: pointer;

            span {
                line-height: 1;
            }

            svg {
                margin-left: 6px;
                width: 20px;
                height: 20px;

                path {
                    stroke: #7f67e9;
                }
            }
        }
    }

    h4.b-aplication-box__title {
        margin-bottom: 0;

        @include bp-down(medium) {
            font-size: 20px;
        }
    }
}
