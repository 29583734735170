.b-two-columns-text {
    &__wrapper {
        gap: 24px;
    }

    &__text {
        flex: 1;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.67;
        color: $color-custom-2;
        opacity: 0.9;

        @include bp-down(large) {
            font-size: 16px;
        }

        p {
            margin: 0;
        }
    }
}
