.c-subscribe-icons {
    &__title {
        margin-bottom: 24px;

        @include bp-down(medium) {
            margin-bottom: 12px;
            font-size: 16px;
        }
    }

    &__lists {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 26px;

        @include bp-down(medium) {
            gap: 16px;
        }

        &--centered {
            justify-content: center;

            @include bp-down(large) {
                justify-content: flex-start;
            }
        }
    }

    &__single-icon {
        display: inline-block;
        vertical-align: middle;
        font-size: 0;
        padding: 8px;
        background-color: $color-custom-1;
        box-shadow: 0 15px 30px rgba(51, 37, 112, 0.08);
        border-radius: 8px;
        overflow: hidden;
        transition: $global-transition;
        text-decoration: none;

        &--link {
            &:hover {
                background-color: rgba($color-custom-1, 0.7);
            }
        }
    }

    &__icon {
        width: 32px;
        height: auto;

        @include bp-down(medium) {
            width: 24px;
        }
    }
}
