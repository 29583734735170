.b-spacer {
    width: 100%;
    padding-top: var(--spacer-top-mobile);
    padding-bottom: var(--spacer-bottom-mobile);
    background-color: var(--spacer-background, #{$color-most-light-grey});

    @include bp(large) {
        padding-top: var(--spacer-top-desktop);
        padding-bottom: var(--spacer-bottom-desktop);
    }

    &__wrapper {
        width: 100%;
    }

    hr {
        width: 100%;
        margin: 0;
        padding: 0;
        height: 1px;
        border: none;
        background-color: $color-brand-6;
    }
}
