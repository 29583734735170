.c-tease {
    $self: &;

    color: $color-black;
    text-decoration: none;
    margin-bottom: 40px;

    --number-columns: 1;

    @include columns;

    @include bp(medium) {
        --number-columns: 2;
    }

    @include bp(large) {
        --number-columns: 3;

        margin-bottom: 56px;
    }

    &__title {
        margin-bottom: 12px;

        @include bp-down(medium) {
            margin-bottom: 8px;
        }
    }

    &__desc {
        margin-bottom: 12px;
        transition: $global-transition;

        @include bp-down(medium) {
            margin-bottom: 16px;
        }
    }

    &__wrapper {
        transition: $global-transition;

        &:hover {
            #{$self}__img {
                transform: scale(1.01);
            }
            #{$self}__desc {
                color: $color-black-v2;
            }
        }
    }

    a {
        text-decoration: none;
    }

    &__available-later {
        display: block;
        margin-top: 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.25;
        letter-spacing: 0.5px;

        @include bp-down(medium) {
            margin-top: 5px;
        }
    }

    &__img {
        width: 100%;
        height: 246px;
        object-fit: cover;
        object-position: center top;
        margin: 0 0 40px 0;
        border-radius: 8px;
        transition: $global-transition;

        @include bp-down(medium) {
            height: 193px;
            margin: 0 0 20px 0;
        }

        &-wrapper {
            display: block;
            width: 100%;
            overflow: hidden;
            border-radius: 8px;
        }
    }

    &__tags {
        margin: 0 0 4px 0;
        gap: 8px;

        @include bp(large) {
            margin: 0;
        }
    }

    &__tag {
        // background: $color-brand-14;
        // color: $color-brand-22;
        // border-radius: 100px;
        // font-weight: 400;
        // font-size: em(17);
        // line-height: 1;
        // padding: 5px em(14.12);
        // margin: 0 em(calc(20 / 1.0625)) em(calc(14 / 1.0625)) 0;
    }

    /* special types tease */

    &--realization {
        @include bp-down(medium) {
            margin-bottom: 64px;
        }

        #{$self}__img {
            margin-bottom: 16px;
        }
        #{$self}__tags {
            margin-bottom: 32px;
        }
        #{$self}__title {
            margin-bottom: 16px;
            font-size: 28px;
            line-height: 1.2;
        }
        #{$self}__desc {
            margin-bottom: 32px;
            font-size: 16px;
            line-height: 1.6;
        }
    }
}
