.b-simple-banner {
    background-color: var(--simple-banner-bg, $color-dark-box);
    padding: 37.5px 0;

    &__wrapper {
        gap: 48px;
        max-width: 1125px;
    }

    &__image,
    &__content {
        flex: 1;
        max-width: 438px;
    }

    &__text {
        color: var(--simple-banner-content-color, $color-white);
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 1.2;
        margin: 0;
    }

    &__btn {
        color: var(--simple-banner-btn-color-text);
        background-color: var(--simple-banner-btn-color-bg);
        border-color: var(--simple-banner-btn-color-bg);
        margin-top: 48px;

        &:hover,
        &:active,
        &:focus {
            color: var(--simple-banner-btn-color-bg);
            background-color: var(--simple-banner-btn-color-text);
            border-color: var(--simple-banner-btn-color-text);
        }
    }
}
