.c-header-extra {
    background-color: $color-brand-22;
    color: $color-custom-1;
    height: 40px;
    margin-top: -24px;
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;

    &__empty {
        width: 30%;
    }

    &__link {
        color: currentColor;
        font-weight: 600;
        text-decoration-line: underline;
        margin-right: 5px;

        &:hover {
            color: $color-brand-4;
        }
    }

    &__text {
        color: currentColor;
    }

    &__menu {
        &-items {
            list-style: none;
            margin: 0;
            padding: 0;
            justify-self: flex-end;
            gap: 0 32px;

            @include bp-down(xlarge) {
                gap: 0 16px;
            }
        }

        &-item {
            margin: 0;
            padding: 0;
        }

        &-link {
            text-decoration: none;
            color: currentColor;

            &:hover {
                color: $color-brand-4;
            }
        }
    }

    &__switcher-lang {
        margin-left: 48px;

        --lang-switcher-main: currentColor;
        --lang-switcher-link: currentColor;
        --lang-switcher-hover: #{$color-brand-4};

        @include bp-down(xlarge) {
            margin-left: 24px;
        }
    }
}
