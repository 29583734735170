.c-language-custom-wmpl {
    .wpml-ls {
        padding: 0;
        margin: 0;

        .wpml-ls-item {
            margin: 0 6px;

            .wpml-ls-link {
                position: relative;
                padding: 5px 0;
                font-size: 18px;
                font-weight: 400;
                line-height: 1.1;
                color: var(--lang-switcher-link, #605f61);
                transition: $global-transition;
                text-transform: uppercase;

                @include bp-down(medium) {
                    padding: 2px 0;
                }

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 2px;
                    background-color: var(--lang-switcher-main, #{$color-black-v2});
                    opacity: 0;
                }

                &:hover {
                    color: var(--lang-switcher-hover, #{$color-black-v2});
                }
            }

            &.wpml-ls-current-language {
                .wpml-ls-link {
                    color: var(--lang-switcher-main, #{$color-black-v2});
                    text-decoration: none;

                    &::after {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
