.c-text {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.6;
    color: $color-custom-1;

    @include bp-down(small) {
        font-size: 16px;
    }

    &--middle-small {
        font-size: 20px;
        line-height: 1.4;

        @include bp-down(medium) {
            font-size: 18px;
        }
    }

    &--middle {
        font-size: 24px;
        line-height: 1.25;

        @include bp-down(medium) {
            font-size: 18px;
        }
    }

    &--weight-strong {
        font-weight: 600;

        @include bp-down(medium) {
            font-weight: 500;
        }
    }

    &--small-opacity {
        color: rgba($color-custom-1, 0.9);
    }

    &--color {
        &-dark {
            color: $color-custom-2;
        }

        &-black {
            color: $color-black;
        }

        &-black-v2 {
            color: $color-black-v2;
        }

        &-gray {
            color: #959596;
        }

        &-black-opacity-v2 {
            color: rgba($color-black-v2, 0.9);
        }

        &-dark-opacity {
            color: rgba($color-custom-2, 0.9);
        }

        &-pink {
            color: $color-brand-4;
        }
    }
}
