.b-flip-cards {
    $space: 109px;
    $time: 0.707s;
    $root: &;

    padding-top: $space;
    padding-bottom: 50px;

    &__wrapper {
        gap: 133px 24px;
        display: flex;
    }

    &__card {
        text-decoration: none;
        perspective: 40rem;
        max-width: 437px;
        width: 100%;
        position: relative;

        &.active {
            #{$root}__front {
                transform: rotateY(-180deg);

                img {
                    opacity: 0;
                }
            }

            #{$root}__back {
                transform: rotateY(0);
            }
        }
    }

    &__back {
        transform: rotateY(180deg);
    }

    &__back,
    &__front {
        transition: all 800ms ease-in-out;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        backface-visibility: hidden;
        background: $color-custom-1;
        box-shadow: 0 20px 30px rgba(51, 37, 112, 0.08);
        border-radius: 8px;
        min-height: 100%;
    }

    &__image {
        margin-top: calc(-1 * $space);
        backface-visibility: hidden;

        img,
        & {
            width: 100%;
            transition: all 500ms ease-in-out;
        }
    }

    &__title {
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 40px;
        margin: 0 0 16px 0;
        color: $color-brand-22;

        @include bp-down(large) {
            font-size: 24px;
        }
    }

    &__text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.38;
        margin: 0 0 32px 0;
        color: $color-black-v2;
    }

    &__content {
        padding: 0 32px calc(55px * 2) 32px;

        @include bp-down(large) {
            padding: 0 32px calc(55px * 2) 32px;
        }

        &--space {
            padding-top: 32px;

            @include bp-down(large) {
                padding-top: 32px;
            }
        }
    }

    &__btn {
        position: absolute;
        bottom: 55px;
        padding: 11.5px 32px;
        font-size: 16px;
        line-height: 1.06;
        margin: 0 auto;
    }
}
