.c-podcasts {
    padding: 120px 0;
    text-align: center;

    @include bp-down(medium) {
        padding: 40px 0 64px;
        text-align: left;
    }

    &__title {
        margin-bottom: 24px;
    }

    &__link {
        // font-size: em(27);
        // line-height: 1.56;

        // @include bp(large) {
        // 	min-width: 336px;
        // }
    }
}
