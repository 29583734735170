.b-thank-you {
    &__box {
        padding: 48px 40px;
        background: $color-custom-1;
        box-shadow: 0 20px 30px rgba(51, 37, 112, 0.08);
        border-radius: 8px;
        max-width: 898px;

        @include bp-down(large) {
            padding: 24px 20px;
        }
    }

    &__heading {
        margin: 0 0 24px 0;

        @include bp-down(large) {
            margin: 0 0 16px 0;
        }
    }

    &__text {
        margin: 0 0 24px 0;

        @include bp-down(large) {
            margin: 0 0 12px 0;
        }

        p {
            margin: 0;

            &:not(:last-of-type) {
                margin: 0 0 48px 0;

                @include bp-down(large) {
                    margin: 0 0 24px 0;
                }
            }
        }
    }

    &__btn {
        margin: 48px 0 0 0;

        @include bp-down(large) {
            margin: 24px 0 0 0;
        }
    }
}
