.c-blogposts {
    font-style: normal;
    padding: 130px 0 120px 0;

    @include bp-down(medium) {
        padding: 64px 0;
    }

    &__mobile-header {
        display: none;
        margin-bottom: 128px;

        @include bp-down(medium) {
            display: block;
        }

        &-sub {
            margin-bottom: 8px;
        }
    }

    &__title {
        // font-weight: 600;
        // font-size: em(36);
        // color: $color-brand-3;
        // margin: 0 0 30px 0;
        margin-bottom: 40px;

        @include bp-down(medium) {
            margin-bottom: 20px;
        }
    }

    &__list {
        // gap: 24px;
        @include bp(medium) {
            --size-gap: 25px;

            gap: var(--size-gap);
        }

        @include bp(large) {
            --size-gap: 24px;
        }
    }

    &__pages {
        list-style: none;
        padding: 0;
        margin: 0 10px;

        li {
            margin: 0;
        }
    }

    &__pagination-wrap {
        margin-top: 30px;

        @include bp-down(medium) {
            margin-top: 0;
        }
    }

    &__arrow {
        line-height: normal;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background: transparent;
        border: none;
        color: $color-brand-22;
        border-radius: 50%;
        padding: 0;
        width: 32px;
        height: 32px;
        text-decoration: none;
        margin: 0 4px;
        font-weight: 500;
        font-size: 20px;
        letter-spacing: 0.5px;

        path {
            transition: $global-transition;
        }

        &:hover {
            background-color: $dots-pagination-bg;

            path {
                stroke: $arrow-gray;
            }
        }
    }

    &__number {
        background: transparent;
        border: none;
        color: $color-brand-22;
        border-radius: 50%;
        padding: 0;
        width: 32px;
        height: 32px;
        text-decoration: none;
        margin: 0 4px;
        line-height: normal;
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 0.5px;

        &--active,
        &:hover {
            background-color: $dots-pagination-bg;
            color: $color-brand-22;
        }

        &--dots {
            border-color: transparent;
            min-width: 25px;
            margin: 0;

            &:hover {
                background-color: transparent;
            }

            @include bp(large) {
                min-width: 48px;
                min-height: 48px;
            }
        }
    }
}
