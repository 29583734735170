.b-works-list {
    position: relative;
    padding: 90px 0;

    @include bp-down(medium) {
        padding: 80px 0;
    }

    &__title {
        margin-bottom: 48px;
    }

    &__works {
        width: 100%;
        max-width: 84%;
        margin: 0 auto;

        @media (max-width: 1445px) {
            max-width: 1130px;
        }
    }

    &__work {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        padding: 31px 24px;
        background: $color-custom-1;
        box-shadow: 0 15px 30px rgba(51, 37, 112, 0.08);
        border-radius: 8px;

        @include bp-down(large) {
            display: block;
        }

        @include bp-down(medium) {
            margin-bottom: 16px;
            padding: 24px 16px 30px;
        }

        &-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            @include bp-down(medium) {
                display: block;
            }
        }

        &-btn-wrap {
            @include bp-down(large) {
                margin-top: 35px;
                text-align: right;
            }
        }

        &-title {
            display: inline-block;
            margin: 0;

            @include bp-down(medium) {
                display: block;
                font-size: 22px;
            }
        }

        &-subtitle {
            display: inline-block;
            margin-left: 32px;
            font-size: 16px;
            line-height: 1.5;
            color: rgba(113, 86, 231, 0.9);

            @include bp-down(medium) {
                display: block;
                margin: 8px 0 0 0;
                font-size: 14px;
            }
        }
    }
}
