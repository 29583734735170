/* stylelint-disable no-descending-specificity */

$media-width: 609px;
$media-padding-desktop: 102;

.b-media-text-button {
    $self: &;

    overflow: hidden;
    padding: 25px 0 50px 0;
    color: $color-black;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include bp(large) {
        padding: em(50) 0 em(100) 0;

        &--equal-padding {
            padding: 78px 0;
        }
    }

    &--dark {
        color: $color-white;
    }

    &--middle-content {
        .b-media-text-button__text,
        .b-media-text-button__media {
            max-width: 80%;

            @include bp-down(medium) {
                max-width: 100%;
                margin: 0;
            }

            video {
                width: 100%;
                border-radius: 8px;
                margin-bottom: 20px;
            }
        }

        .b-media-text-button__title {
            font-size: 20px;
            line-height: 1.4;
            font-weight: 400;

            @include bp-down(medium) {
                font-size: 16px;
            }
        }
    }

    &__media {
        width: 100%;
        margin: 0 0 40px 0;
        text-align: center;

        @include bp-down(medium) {
            margin: 0 0 32px 0;
        }

        @include bp(large) {
            max-width: calc(#{$media-width} + vw($media-padding-desktop));
            margin: 0;
        }

        @include bp(full) {
            max-width: calc(#{$media-width} + #{$media-padding-desktop + 'px'});
        }

        &--row {
            @include bp(large) {
                padding: 0 0 0 vw($media-padding-desktop);
            }

            @include bp(full) {
                padding: 0 0 0 #{$media-padding-desktop + 'px'};
            }
        }

        &--row-reverse {
            @include bp(large) {
                padding: 0 vw($media-padding-desktop) 0 0;
            }

            @include bp(full) {
                padding: 0 #{$media-padding-desktop + 'px'} 0 0;
            }
        }
    }

    &__text {
        width: 100%;

        @include bp(large) {
            max-width: calc(100% - #{$media-width} - vw($media-padding-desktop));
        }

        @include bp(full) {
            max-width: calc(100% - #{$media-width} - #{$media-padding-desktop + 'px'});
        }

        &--row {
            @include bp(large) {
                padding: 0 vw(114) 0 vw(114);
            }

            @include bp(full) {
                padding: 0 114px 0 114px;
            }
        }

        &--row-reverse {
            @include bp(large) {
                padding: 0 vw(114) 0 vw(114);
            }

            @include bp(full) {
                padding: 0 114px 0 114px;
            }
        }
    }

    &__text,
    &__media {
        z-index: 2;
        position: relative;
    }

    &__title {
        font-style: normal;
        // font-weight: 600;
        // font-size: em(50);
        // line-height: 1.44;
        text-align: left;
        margin: 0 0 24px 0;

        // @include bp(large) {
        // 	font-size: em(60);
        // 	line-height: 1.37;
        // 	text-align: left;
        // }

        @include bp-down(medium) {
            margin: 0 0 16px 0;
        }

        &--light {
            opacity: 0.9;
            font-size: em(50);
            line-height: 1.4;

            @include bp(large) {
                font-size: em(80);
                line-height: 1.25;
            }
        }
    }

    &__image {
        border-radius: 8px;
    }

    &__content {
        font-style: normal;
        font-weight: 400;
        margin: 0 0 48px 0;
        text-align: left;
        font-size: 18px;
        line-height: 1.67;

        @include bp-down(medium) {
            font-size: 16px;
            line-height: 1.5;
            margin: 0 0 32px 0;
        }

        // @include bp(large) {
        // 	font-size: em(26);
        // 	line-height: 1.67;
        // 	max-width: 700px;
        // 	text-align: left;
        // }

        &--light {
            color: $color-custom-2;
        }
    }

    &--equal-padding {
        padding: 50px 0;
        #{$self}__media {
            video {
                margin-bottom: 0;
            }
        }
    }
}
