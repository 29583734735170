.b-podcast-list {
    padding: 90px 0;

    &--pading-small {
        padding: 20px 0;
    }

    @include bp-down(medium) {
        padding: 30px 0;

        &--pading-small {
            padding: 10px 0;
        }
    }

    &__header {
        margin-bottom: 48px;
    }

    &__wrapper {
        max-width: 80%;
        margin: 0 auto 64px;

        @include bp-down(large) {
            max-width: 100%;
        }
    }
}
