.s-simple-se {
    position: relative;
    padding: 64px 0;

    @include bp-down(medium) {
        padding: 40px 0;
    }

    &__content {
        &--centered {
            text-align: center;

            @include bp-down(medium) {
                text-align: left;
            }
        }

        &--max-width {
            max-width: 900px;
            margin: 0 auto;

            &-v2 {
                max-width: 1150px;
                margin: 0 auto;
            }
        }

        @for $i from 1 through 5 {
            h#{$i} {
                margin-bottom: 20px;
                color: $color-black-v2;

                @if $i == 1 {
                    font-size: 80px;
                } @else if $i == 2 {
                    font-size: 40px;
                } @else if $i == 3 {
                    font-size: 32px;
                } @else if $i == 4 {
                    font-size: 24px;
                }

                @include bp-down(medium) {
                    margin-bottom: 12px;

                    @if $i == 1 {
                        font-size: 36px;
                    } @else if $i == 2 {
                        font-size: 20px;
                    } @else if $i == 3 {
                        font-size: 19px;
                    } @else if $i == 4 {
                        font-size: 19px;
                    }

                    font-weight: 500;
                }
            }
        }

        p {
            margin-bottom: 20px;

            @include bp-down(medium) {
                font-size: 16px;
            }
        }
    }
}
