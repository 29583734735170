.c-sub-heading {
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.62em;
    font-size: 20px;
    line-height: 1.2;
    color: $color-brand-22;
    text-transform: uppercase;
    margin: 0 0 12px 0;

    @include bp-down(medium) {
        font-size: 16px;
        margin: 0 0 8px 0;
        letter-spacing: 0.5em;
    }
}
