.c-related {
    padding: 180px 0 100px;
    margin: 0 0 30px 0;

    &__heading {
        margin-bottom: 48px;
    }

    &--small-padding {
        padding: 80px 0 90px;
    }

    @include bp-down(large) {
        padding: 59px 0 50px;
    }

    &__list {
        @include bp(medium) {
            --size-gap: 24px;

            gap: var(--size-gap);
        }
    }

    &__link-wrapper {
        width: 100%;
        text-align: center;
    }
}
