.b-heading-text-btn {
    color: $color-brand-3;
    font-style: normal;
    text-align: center;
    padding: 0 0 em(115) 0;

    h4 {
        font-weight: 600;
        font-size: em(36);
        line-height: 1.39;
        margin: 0;
        padding: 0;
        max-width: 906px;

        @include bp(large) {
            font-size: em(60);
            line-height: 1.37;
        }
    }

    p {
        font-weight: 400;
        font-size: em(30);
        line-height: 1.67;
        margin: 15px 0 0 0;
        color: $color-brand-24;

        @include bp(large) {
            font-size: em(26);
            line-height: 1.73;
        }
    }

    a {
        margin: 43px 0 0 0;
        padding: 12px 24px;

        @include bp(large) {
            padding: 17px 40px;
        }

        min-width: 239px;
    }
}
