.c-box-person {
    text-align: center;
    padding: 15px 0;
    width: 100%;

    &__thumb {
        width: 100%;
        height: auto;
        border-radius: 8px;
        overflow: hidden;
        margin: 0 0 25px;
        padding: 0;

        img {
            height: auto;
            width: 100%;
            border-radius: 8px;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 1.2;
        margin: 0 0 8px 0;
        color: $color-brand-3;

        @include bp-down(small) {
            margin: 0 0 2px 0;
            font-size: 16px;
            line-height: 1.6;
        }
    }

    &__desc {
        font-weight: 400;
        font-size: 20px;
        line-height: 1.4;
        color: $color-custom-2;

        @include bp-down(small) {
            font-size: 14px;
            line-height: 1.57;
        }
    }

    &__text {
        text-decoration: none;
        margin: 0 auto 6px auto;

        @include bp-down(small) {
            font-size: 14px;
        }
    }
}
