.u-d {
    &-flex {
        display: flex;
    }

    &-block {
        display: block;
    }

    &-inline-flex {
        display: inline-flex;
    }

    &-none {
        display: none;
    }

    &-md {
        &-flex {
            @include bp(medium) {
                display: flex;
            }
        }

        &-block {
            @include bp(medium) {
                display: block;
            }
        }

        &-none {
            @include bp(medium) {
                display: none;
            }
        }
    }

    &-lg {
        &-flex {
            @include bp(large) {
                display: flex;
            }
        }

        &-block {
            @include bp(large) {
                display: block;
            }
        }

        &-none {
            @include bp(large) {
                display: none;
            }
        }
    }
}

.u-justify {
    &-content {
        &-between {
            justify-content: space-between;
        }

        &-around {
            justify-content: space-around;
        }

        &-end {
            justify-content: end;
        }

        &-start {
            justify-content: flex-start;
        }

        &-center {
            justify-content: center;
        }

        &-md {
            &-between {
                @include bp(medium) {
                    justify-content: space-between;
                }
            }

            &-center {
                @include bp(medium) {
                    justify-content: center;
                }
            }

            &-start {
                @include bp(medium) {
                    justify-content: flex-start;
                }
            }
        }

        &-lg {
            &-between {
                @include bp(large) {
                    justify-content: space-between;
                }
            }

            &-around {
                @include bp(large) {
                    justify-content: space-around;
                }
            }

            &-center {
                @include bp(large) {
                    justify-content: center;
                }
            }

            &-start {
                @include bp(large) {
                    justify-content: flex-start;
                }
            }

            &-end {
                @include bp(large) {
                    justify-content: flex-end;
                }
            }
        }
    }
}

.u-align {
    &-items {
        &-center {
            align-items: center;
        }

        &-start {
            align-items: flex-start;
        }

        &-end {
            align-items: flex-end;
        }

        &-stretch {
            align-items: stretch;
        }

        &-md {
            &-center {
                @include bp(medium) {
                    align-items: center;
                }
            }

            &-start {
                @include bp(medium) {
                    align-items: flex-start;
                }
            }

            &-end {
                @include bp(medium) {
                    align-items: flex-end;
                }
            }

            &-stretch {
                @include bp(medium) {
                    align-items: stretch;
                }
            }
        }

        &-lg {
            &-center {
                @include bp(large) {
                    align-items: center;
                }
            }

            &-start {
                @include bp(large) {
                    align-items: flex-start;
                }
            }

            &-end {
                @include bp(large) {
                    align-items: flex-end;
                }
            }

            &-stretch {
                @include bp(large) {
                    align-items: stretch;
                }
            }
        }
    }

    &-content {
        &-center {
            align-content: center;
        }

        &-start {
            align-content: flex-start;
        }

        &-stretch {
            align-content: stretch;
        }

        &-md {
            &-center {
                @include bp(medium) {
                    align-content: center;
                }
            }

            &-start {
                @include bp(medium) {
                    align-content: flex-start;
                }
            }

            &-stretch {
                @include bp(medium) {
                    align-content: stretch;
                }
            }
        }
    }
}

.u-flex {
    &-wrap {
        flex-wrap: wrap;
    }

    &-nowrap {
        flex-wrap: nowrap;
    }

    &-column {
        flex-direction: column;

        &-reverse {
            flex-direction: column-reverse;
        }
    }

    &-row {
        flex-direction: row;

        &-reverse {
            flex-direction: row-reverse;
        }
    }

    &-md {
        &-wrap {
            @include bp(medium) {
                flex-wrap: wrap;
            }
        }

        &-nowrap {
            @include bp(medium) {
                flex-wrap: nowrap;
            }
        }

        &-column {
            @include bp(medium) {
                flex-direction: column;
            }
        }

        &-row {
            @include bp(medium) {
                flex-direction: row;
            }

            &-reverse {
                @include bp(medium) {
                    flex-direction: row-reverse;
                }
            }
        }
    }

    &-lg {
        &-wrap {
            @include bp(large) {
                flex-wrap: wrap;
            }
        }

        &-nowrap {
            @include bp(large) {
                flex-wrap: nowrap;
            }
        }

        &-column {
            @include bp(large) {
                flex-direction: column;
            }
        }

        &-row {
            @include bp(large) {
                flex-direction: row;
            }

            &-reverse {
                @include bp(large) {
                    flex-direction: row-reverse;
                }
            }
        }
    }
}
