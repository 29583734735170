.c-social-list {
    margin: 0;
    padding: 0;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 8px 16px;

    &__item {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        // margin: 4px 8px;

        &:last-child {
            // margin: 4px 0 4px 8px;
        }

        &:first-child {
            // margin: 4px 8px 4px 0;
        }
    }

    &__link {
        display: flex;
        text-decoration: none;

        svg rect {
            transition: $global-transition;
        }

        &:hover {
            svg rect {
                fill: $color-brand-4;
            }
        }
    }
}
