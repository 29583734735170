.b-contact-form {
    font-style: normal;
    background-color: var(--contact-form-bg);

    &--bg {
        padding: em(80) 0;
    }

    &__heading {
        // color: $color-black;
        // font-weight: 600;
        // font-size: em(54);
        // line-height: 1.37;
        text-align: left;
        margin: 0 0 88px 0;

        @include bp-down(medium) {
            margin: 0 0 48px 0;
        }
    }

    &__form {
        max-width: 1200px;
        margin: 0 auto;
    }
}
