.b-team {
    font-style: normal;
    padding-bottom: em(30);

    @include bp(large) {
        padding-bottom: em(75);
    }

    --border-color: #{$color-brand-10};
    --border-color-section: #{$color-brand-12};
    --swiper-pagination-bullet-opacity: transparent;
    --swiper-pagination-color: transparent;
    --swiper-pagination-bullet-inactive-color: transparent;
    --swiper-pagination-bullet-width: 13px;
    --swiper-pagination-bullet-height: 13px;
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-bullet-horizontal-gap: 6.5px;
    --swiper-color: #{$color-brand-11};
    --swiper-background-arrow: #{$color-brand-8};
    --swiper-background-arrow-hover: rgba(134, 106, 255, 0.2);
    // --swiper-color-arrow: #{$color-brand-9};
    --swiper-color-arrow: #866aff;

    &__wrapper {
        @include bp-down(small) {
            padding-left: calc(#{$wrapper-gutter + 'px'} / 2);
            padding-right: calc(#{$wrapper-gutter + 'px'} / 2);
        }
    }

    &__list-big-wrapp {
        position: relative;
        padding: 0 65px;
        overflow: hidden;

        &--padding-disable {
            padding: 0 20px;
        }

        @include bp-down(medium) {
            padding: 0 100px;

            &--padding-disable {
                padding: 0;
            }
        }

        @include bp-down(small) {
            padding: 0 78px;

            &--padding-disable {
                padding: 0;
            }
        }
    }

    &__heading {
        margin-bottom: 48px;
        // font-weight: 600;
        // font-size: em(50);
        // line-height: 1.4;
        // text-align: center;
        // margin: 0 0 18px 0;
        // color: $color-brand-3;

        // @include bp(large) {
        // 	font-size: em(60);
        // 	line-height: 1.37;
        // 	margin: 0 0 em(16.26) 0;
        // }
        @include bp-down(medium) {
            margin-bottom: 40px;
        }
    }

    &__normal-grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 16px 64px;

        @include bp-down(large) {
            gap: 16px 30px;
        }

        @include bp-down(medium) {
            gap: 16px 11px;
        }
    }

    &__wrap-person {
        display: block;
        width: calc(25% - 48px);

        @include bp-down(large) {
            width: calc(33% - 23px);
        }

        @include bp-down(medium) {
            width: calc(50% - 8px);

            .b-team__person--v2 {
                padding: 0;
            }

            .b-team__thumb--v2 {
                margin-bottom: 8px;
            }
        }
    }

    &__person {
        text-align: center;
        padding: 15px 0;

        &--v2 {
            width: 100%;
        }

        h6 {
            font-weight: 600;
            // font-size: em(24);
            font-size: 20px;
            line-height: 1.2;
            margin: 0 0 8px 0;
            color: $color-brand-3;

            @include bp-down(small) {
                margin: 0 0 2px 0;
                font-size: 16px;
                line-height: 1.6;
            }
        }

        p {
            font-weight: 400;
            // font-size: em(19);
            font-size: 20px;
            line-height: 1.4;
            color: $color-custom-2;

            @include bp-down(small) {
                font-size: 14px;
                line-height: 1.57;
            }
        }
    }

    &__thumb {
        margin: 0 auto 25px auto;
        padding: 8px;
        background-color: $color-white;
        box-shadow: 0 1px 24px rgba(0, 0, 0, 0.05);
        width: 160px;
        height: 160px;
        border-radius: 50%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }

        &--v2 {
            width: 100%;
            height: auto;
            border-radius: 8px;
            overflow: hidden;
            margin: 0 0 25px;
            padding: 0;

            img {
                height: auto;
                border-radius: 8px;
            }
        }
    }

    &__next {
        right: 0;
    }

    &__prev {
        left: 0;
    }

    &__next,
    &__prev {
        --swiper-navigation-size: 40px;

        width: var(--swiper-navigation-size);
        background: var(--swiper-background-arrow);
        border-radius: 100px;
        transition: $global-transition;
        // top: 100px;
        top: 50%;
        transform: translateY(-50%);

        @include bp-down(medium) {
            background-color: transparent;
        }

        @include bp-down(small) {
            transform: translateY(-100%);
        }

        svg {
            width: 10px;
        }

        &:hover {
            background-color: var(--swiper-background-arrow-hover);

            @include bp-down(medium) {
                background-color: transparent;
            }
        }

        &::after {
            content: none;
            display: none;
        }

        path {
            fill: var(--swiper-color-arrow);
        }

        // @include bp(small) {
        // 	--swiper-navigation-size: 68px;
        // }

        @include bp(large) {
            top: 50%;

            --swiper-navigation-size: 60px;
        }
    }
}
