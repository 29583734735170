.c-pagination {
    &__pages {
        list-style: none;
        padding: 0;
        margin: 0 10px;

        li {
            margin: 0;
        }
    }

    &__arrow {
        line-height: normal;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background: transparent;
        border: none;
        color: $color-brand-22;
        border-radius: 50%;
        padding: 0;
        width: 32px;
        height: 32px;
        text-decoration: none;
        margin: 0 4px;
        font-weight: 500;
        font-size: 20px;
        letter-spacing: 0.5px;

        path {
            transition: $global-transition;
        }

        &:hover {
            background-color: $dots-pagination-bg;

            path {
                stroke: $arrow-gray;
            }
        }
    }

    &__number {
        background: transparent;
        border: none;
        color: $color-brand-22;
        border-radius: 50%;
        padding: 0;
        width: 32px;
        height: 32px;
        text-decoration: none;
        margin: 0 4px;
        line-height: normal;
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 0.5px;

        &--active,
        &:hover {
            background-color: $dots-pagination-bg;
            color: $color-brand-22;
        }

        &--dots {
            border-color: transparent;
            min-width: 25px;
            margin: 0;

            &:hover {
                background-color: transparent;
            }

            @include bp(large) {
                min-width: 48px;
                min-height: 48px;
            }
        }
    }
}
