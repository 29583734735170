/* stylelint-disable  selector-no-qualifying-type */

.wpcf7 {
    --input-margin: 45px;

    .wpcf7-form {
        p {
            margin: 0;
        }

        .wpcf7-row {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: stretch;

            @include bp(large) {
                flex-wrap: nowrap;
                gap: 48px;
            }

            &--small {
                @include bp(large) {
                    max-width: 1389px;
                    margin: 0 auto;
                }
            }
        }

        .wpcf7-column {
            flex: 100%;
            margin-bottom: 50px;

            @include bp(large) {
                flex: 50%;
            }

            @include bp-down(medium) {
                margin-bottom: 40px;
            }
        }

        .wpcf7-column--textarea {
            > label {
                @include bp(large) {
                    height: calc(100% - (var(--input-margin) * 2));
                }
            }

            .wpcf7-form-control-wrap {
                display: block;

                @include bp(large) {
                    height: calc(100% - (var(--input-margin) + 2px));
                }

                @include bp(full) {
                    height: calc(100% - (var(--input-margin) + 18px));
                }
            }

            textarea {
                @include bp(medium) {
                    height: 100%;
                }
            }
        }

        .wpcf7-normal-input-label {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 1.25;
            color: $color-black-v2;
            width: 100%;
            margin: 0 0 40px 0;
            display: block;
            letter-spacing: 0.5px;

            i {
                color: $color-violet-v2;
                font-style: normal;
            }

            input,
            textarea {
                margin-top: 10px;
                color: $color-brand-24;
                font-family: $font-sans-serif;
                font-weight: 400;
                border: solid $color-brand-34;
                border-width: 0 0 1px 0;
                font-size: 18px;
                height: 30px;
                line-height: 30px;
                width: 100%;
                outline: none;
                padding: 0;
                border-radius: 0;
                background: transparent;

                @include bp-down(medium) {
                    margin-top: 14px;
                    height: 26px;
                    font-size: 16px;
                    line-height: 26px;
                }
            }

            textarea {
                height: auto;
                min-height: 30px;
                line-height: 1.2;
            }

            .wpcf7-not-valid {
                border-color: $error-basic;
            }
        }

        .wpcf7-acceptance {
            display: inline-flex;
            margin-bottom: 12px;

            .wpcf7-list-item {
                margin: 0;

                > label {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                }
            }

            .wpcf7-list-item-label {
                display: inline;
                font-style: normal;
                color: $color-black-v2;
                font-family: $font-sans-serif;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                margin: 0;
                position: relative;
                padding: 0 24px 0 40px;
                transition: $global-transition;
                border: 1px solid transparent;
                cursor: pointer;

                --border-radius: 4px;
                --checkbox-color: #{$color-brand-22};
                --checkbox-background: transparent;

                @include bp-down(large) {
                    padding: 0 20px 0 40px;
                }

                &::before {
                    content: '';
                    width: 24px;
                    height: 24px;
                    background-color: var(--checkbox-background);
                    border: 1px solid var(--checkbox-color);
                    border-radius: var(--border-radius);
                    display: inline-block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    justify-content: center;
                    align-items: center;
                    transition: $global-transition;
                    background-size: 24px 24px;
                    background-repeat: no-repeat;
                    background-position: center;
                }

                a {
                    color: $color-violet-v2;
                    text-decoration: none;
                }
            }

            input[type='checkbox'] {
                display: none;

                &:checked + span {
                    &::before {
                        background-image: url('~assets/images/icon-checked-v2.svg');
                    }
                }
            }
        }

        // .wpcf7-checkbox {
        // 	--gap: 24px;

        // 	display: flex;
        // 	align-items: stretch;
        // 	justify-content: center;
        // 	flex-wrap: wrap;
        // 	gap: 22px 24px;
        // 	margin-bottom: em(90);

        // 	@include bp-down(medium) {
        // 		gap: 16px;
        // 		margin-bottom: 56px;
        // 	}

        // 	// @include bp(large) {
        // 	// 	--gap: 48px;

        // 	// 	// gap: 41px var(--gap);
        // 	// 	gap: 22px 24px;
        // 	// 	margin-bottom: em(150);
        // 	// }

        // 	.wpcf7-list-item {
        // 		margin: 0;
        // 		display: flex;
        // 		justify-content: flex-start;
        // 		align-items: center;
        // 		flex: 100%;

        // 		@include bp(medium) {
        // 			flex: calc(50% - var(--gap));
        // 		}

        // 		@include bp(large) {
        // 			flex: calc(33.33% - var(--gap));
        // 		}

        // 		> label {
        // 			width: 100%;
        // 			height: 100%;
        // 			cursor: pointer;
        // 		}
        // 	}

        // 	.wpcf7-list-item-label {
        // 		font-style: normal;
        // 		font-weight: 600;
        // 		// font-size: em(26);
        // 		font-size: 18px;
        // 		line-height: 1.6;
        // 		color: $color-black-v2;
        // 		// opacity: 0.9;
        // 		margin: 0;
        // 		// padding: 0 0 0 66px;
        // 		display: flex;
        // 		justify-content: flex-start;
        // 		align-items: center;
        // 		position: relative;
        // 		// padding: 20px;
        // 		padding: 30px 24px 30px 72px;
        // 		min-height: 96px;
        // 		background: $color-white;
        // 		box-shadow: 0 15px 30px rgba(51, 37, 112, 0.08);
        // 		border-radius: 8px;
        // 		transition: $global-transition;
        // 		border: 1px solid transparent;
        // 		height: 100%;

        // 		$checkbox-shadow: rgba($color-brand-27, 0.3);

        // 		--border-radius: 4px;
        // 		--checkbox-color: #{$color-brand-10};
        // 		--checkbox-background: #{$color-white};
        // 		--checkbox-shadow: 0 0 18px 0 #{$checkbox-shadow};

        // 		// @include bp(medium) {
        // 		// 	min-height: 150px;
        // 		// 	padding: 15px 15px 15px 111px;
        // 		// }
        // 		@include bp-down(medium) {
        // 			font-size: 16px;
        // 			min-height: auto;
        // 			padding: 27px 20px 27px 64px;
        // 		}

        // 		&:hover {
        // 			box-shadow: var(--checkbox-shadow);
        // 		}

        // 		&::before {
        // 			content: '';
        // 			width: 24px;
        // 			height: 24px;
        // 			background-color: var(--checkbox-background);
        // 			border: 1px solid var(--checkbox-color);
        // 			border-radius: var(--border-radius);
        // 			display: inline-block;
        // 			position: absolute;
        // 			left: 24px;
        // 			justify-content: center;
        // 			align-items: center;
        // 		}
        // 	}

        // 	input[type='checkbox'] {
        // 		display: none;

        // 		&:checked + .wpcf7-list-item-label {
        // 			--checkbox-color: #{$color-brand-22};
        // 			--checkbox-background: #{$color-brand-22};

        // 			box-shadow: var(--checkbox-shadow);
        // 			border-color: var(--checkbox-color);

        // 			&::before {
        // 				content: url('~assets/images/icon-checked-v2.svg');
        // 				width: 24px;
        // 				height: 24px;
        // 			}
        // 		}
        // 	}
        // }

        .wpcf7-not-valid-tip {
            margin-top: 10px;
            font-size: 12px;
            position: absolute;
            top: auto;
            text-transform: none;
            color: $error-basic;
        }

        .wpcf7-response-output {
            font-size: em(16);
            padding: 20px;
            text-align: center;
            margin-left: 0;
            margin-right: 0;
            border-color: $color-black-v2;
        }
    }
}

.c-form {
    &__btn-wrapper {
        position: relative;
        text-align: center;
        margin: 52px 0 0 0;

        @include bp-down(large) {
            margin: 24px 0 0 0;
        }

        &-inner {
            position: relative;
            display: inline-block;
            vertical-align: middle;

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 23px;
                transform: translateY(-50%);
                width: 20px;
                height: 20px;
                background: url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.625 10H17.375' stroke='%23FBFBFB' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.75 4.375L17.375 10L11.75 15.625' stroke='%23FBFBFB' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")
                    no-repeat center center;
                background-size: cover;
                transition: $global-transition;
            }

            &:hover {
                &::after {
                    background: url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.625 10H17.375' stroke='%230A0A0A' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.75 4.375L17.375 10L11.75 15.625' stroke='%230A0A0A' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")
                        no-repeat center center;
                }
            }

            &--down {
                &::after {
                    background: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 3.56689V17.3169' stroke='%23FBFBFB' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4.875 11.6919L10.5 17.3169L16.125 11.6919' stroke='%23FBFBFB' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
                        no-repeat center center;
                }

                &:hover {
                    &::after {
                        background: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 3.56689V17.3169' stroke='%230A0A0A' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4.875 11.6919L10.5 17.3169L16.125 11.6919' stroke='%230A0A0A' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
                            no-repeat center center;
                    }
                }
            }
        }

        .wpcf7-spinner {
            margin: 0;
            padding: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -30px;
        }
    }

    &__btn {
        margin: 0;
        padding: 12px 52px 12px 28px;
    }
}

div.wpcf7 {
    form.invalid .wpcf7-response-output,
    form.unaccepted .wpcf7-response-output,
    form.payment-required .wpcf7-response-output {
        border-color: $color-brand-22;
        display: none;
    }

    form.sent {
        .wpcf7-response-output {
            display: none;
        }
    }
}
