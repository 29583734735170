.b-full-image {
    position: relative;
    font-size: 0;

    &__wrapper {
        display: block;
        width: 100%;
        height: auto;
        font-size: 0;

        &--mobile {
            display: none;

            @include bp-down(medium) {
                display: block;
            }
        }

        &--desktop {
            @include bp-down(medium) {
                display: none;
            }
        }
    }

    &__img {
        width: 100%;
        height: auto;
    }
}
