.c-animation-bg {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    top: 0;
    left: 0;
    // background-color: $color-black-v2;
    overflow: hidden;

    &__inner {
        display: block;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(10px);
        // mix-blend-mode: screen;
        // background-color: rgba(10, 10, 10, 0.1);
    }

    &__img {
        position: absolute;
        top: 50%;
        left: 50%;
        right: 0;
        bottom: 0;
        width: 100%;
        height: auto;
        transform: translate(-50%, -50%);
        opacity: 0;
        filter: blur(10px);
        transition: opacity 1.2s linear;

        @include bp-down(small) {
            width: 200%;
            max-width: unset;
        }

        &.show {
            opacity: 1;
        }
    }
}
