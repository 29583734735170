.c-tag {
    background-color: $color-custom-2;
    border-radius: 50px;
    font-weight: 400;
    font-size: 14px;
    color: $color-text-white;
    line-height: 1.4;
    padding: 4px 12px;
    letter-spacing: 0.5px;
    text-transform: capitalize;

    &--violet {
        background-color: #e3ddfa;
        color: #483697;
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
    }
}
