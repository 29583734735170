// ==========================================================================
// #GLOBAL
// ==========================================================================
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

$global-radius: 3px !default;
$global-transition: all 300ms ease-in-out !default;

/* Fonts
   ========================================================================== */

$font-name: 'Jost';
$font-sans-serif: $font-name, sans-serif !default;

/* Breakpoints
   ========================================================================== */

$breakpoints: (
    small: 480px,
    medium: 768px,
    large: 1024px,
    xlarge: 1280px,
    full: 2000px,
);

$wrapper: 1750;
$wrapper-gutter: 35;

/* Colors
   ========================================================================== */

// Colors shouldn't be used directly but reassigned
$color-white: #fff;
$color-black: #000;
$color-black-v2: #0a0a0a;
$color-most-light-grey: #d9d9d9;
$color-very-light-grey: #bdbdbd;
$color-light-grey: #999;
$color-medium-grey: #505050;
$color-dark-grey: #333;
$color-brand-1: #5b177b;
$color-brand-2: #7536bc;
$color-brand-3: #060106;
$color-brand-4: #ff4b61;
$color-brand-5: #cfcfcf;
$color-brand-6: #cbc9c9;
$color-brand-7: #595959;
$color-brand-8: rgba(234, 212, 254, 0.15);
$color-brand-9: #181719;
$color-brand-10: #dbb5fe;
$color-brand-11: #ff00a8;
$color-brand-12: #e4e4e4;
$color-brand-13: #9f55da;
$color-brand-14: #f3f0ff;
$color-brand-15: rgba(162, 177, 255, 0.25);
$color-brand-16: rgba(80, 80, 80, 0.7);
$color-brand-17: #cecece;
$color-brand-18: #a2b1ff;
$color-brand-19: #1a1a1a;
$color-brand-20: rgba(80, 80, 80, 0.9);
$color-brand-21: rgba($color-white, 0.2);
$color-brand-22: #7156e7;
$color-brand-23: #828082;
$color-brand-24: #514d51;
$color-brand-25: #d7ceff;
$color-brand-26: #bcadff;
$color-brand-27: #7c87e6;
$color-brand-28: #f00;
$color-brand-29: #9b999b;
$color-brand-30: #e6e6e6;
$color-brand-31: #6a676a;
$color-brand-32: #d5d5d5;
$color-brand-33: #483697;
$color-brand-34: #959596;
$color-custom-1: #fbfbfb;
$color-custom-2: #404040;
$border-gray-color: #eaeaea;
$color-primary-600: #5c46bf;
$color-dark-box: #202020;
$arrow-gray: #807f81;
$dots-pagination-bg: #f1eefd;
$color-violet-v2: #866aff;
$color-gray-v3: #e8e8e8;
$error-basic: #eb3f3f;

// Text
$color-bg: $color-white;
$color-text: $color-black;
$color-btn-v2: $color-black-v2;
$color-text-white: $color-custom-1;

// Links
$color-link: $color-brand-3;
$color-hover: $color-brand-24;

// Borders
$color-border: $color-very-light-grey;

// Fills
$color-fill: $color-very-light-grey;
