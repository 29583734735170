.c-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(32, 32, 32, 0.5);
    z-index: 999999;
    display: none;

    &.active {
        display: block;
    }

    &__inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 32px);
        max-width: 900px;
        padding: 48px 40px;
        border-radius: 8px;
        background-color: $color-custom-1;

        @include bp-down(medium) {
            padding: 24px;
        }
    }

    &__title {
        margin-bottom: 32px;

        @include bp-down(medium) {
            margin-bottom: 16px;
        }
    }

    &__btn-wrap {
        margin-top: 50px;
        text-align: right;

        @include bp-down(medium) {
            margin-top: 40px;
        }
    }
}
