.u-p {
    &-relative {
        position: relative;
    }

    &-lg {
        &-relative {
            @include bp(large) {
                position: relative;
            }
        }
    }
}
