.b-logos {
    &--space {
        padding: 60px 0;

        @include bp(large) {
            padding: em(154) 0;
        }
    }

    &__heading {
        padding: 0;
        margin: 0 0 48px 0;
    }

    &__images {
        --size-gap: 60px;

        gap: 50px var(--size-gap);

        @include bp-down(medium) {
            display: none;

            &--display-mobile {
                display: flex;
                gap: 20px 26px;
            }
        }
    }

    &__image {
        --number-columns: 2;

        @include columns;

        @include bp(small) {
            --number-columns: 2;
        }

        @include bp(medium) {
            --number-columns: 4;
        }

        @include bp(xlarge) {
            --number-columns: auto;
        }

        @include bp-down(small) {
            &--padd-mobile {
                padding: 0 20px;
            }
        }

        img {
            display: block;
            padding: 0;
            margin: 0 auto;
        }
    }

    &__image-mobile {
        display: block;
        text-align: center;
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__slider-mobile {
        display: none;

        @include bp-down(medium) {
            display: block;
        }
    }
}

.b-logos-new {
    &--space {
        padding: 60px 0;

        @include bp(large) {
            padding: em(154) 0;
        }
    }

    &__heading {
        padding: 0;
        margin: 0 0 48px 0;

        @include bp(large) {
            margin: 0 0 40px 0;
        }
    }

    &__col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        gap: 16px;

        @include bp(medium) {
            gap: 24px;
        }
    }

    &__image {
        height: 64px;
        background: $color-dark-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        padding: 10px 16px;

        @include bp(medium) {
            height: 80px;
            padding: 10px 50px;
        }

        @include bp(large) {
            height: 120px;
            padding: 10px 70px;
        }
    }

    .c-arrows-default {
        margin-left: 0;

        @include bp-down(medium) {
            padding-top: 54px;
        }

        &__arrow--left {
            margin-right: 20px;
        }
    }
}

.b-logos-new__images--light {
    .b-logos__heading {
        color: $color-black;
    }

    .b-logos-new__image {
        background: white;
        box-shadow: 0 20px 30px 0 rgba(51, 37, 112, 0.08);

        img {
            max-height: 64px;
        }
    }

    .b-logos-new__arrow-wrapper {
        svg path {
            stroke: $color-black !important;
        }

        .c-arrows-default__arrow:hover {
            svg path {
                stroke: $color-brand-4 !important;
            }
        }
    }
}
