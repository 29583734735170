.c-box-realization {
    $self: &;

    &__content {
        order: 1;
        width: 100%;
        padding-top: 40px;

        @include bp-down(medium) {
            padding-top: 0;
        }

        @include bp(medium) {
            width: 50%;
        }

        &--even {
            @include bp(medium) {
                padding-right: 80px;
                padding-left: 80px;
            }

            @include bp-down(large) {
                padding-left: 0;
            }
        }

        &--odd {
            @include bp(medium) {
                padding-left: 80px;
                padding-right: 80px;
            }

            @include bp-down(large) {
                padding-right: 0;
            }
        }
    }

    &__available-later {
        display: block;
        margin-top: 48px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.25;
        letter-spacing: 0.5px;

        @include bp-down(medium) {
            margin-top: 32px;
        }
    }

    &__thumb {
        width: 100%;
        margin: 0 0 30px 0;
        border-radius: 8px;
        // background-color: $color-brand-3;

        &--radius-half {
            border-radius: 8px 0 0 8px;

            @include bp-down(medium) {
                border-radius: 0;
            }
        }

        @include bp(medium) {
            width: 50%;
            margin: 0;
        }

        @include bp-down(medium) {
            margin: 0 0 18px 0;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: $global-transition;
            border-radius: 8px;

            &--radius-half {
                border-radius: 8px 0 0 8px;

                @include bp-down(medium) {
                    border-radius: 0;
                }
            }

            @include bp-down(medium) {
                max-height: em(542);

                // stylelint-disable selector-no-qualifying-type
                &.disable-radius-mob {
                    border-radius: 0;
                }
            }
        }

        &--even {
            @include bp(medium) {
                order: 2;
            }
        }

        &--dark {
            img {
                opacity: 0.4;

                @include bp-down(medium) {
                    opacity: 1;
                }
            }
        }
    }

    &__tags {
        margin: 0 0 48px 0;
        gap: 8px;

        @include bp-down(medium) {
            margin: 0 0 32px 0;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 40px;
        line-height: 1.2;
        margin: 0 0 24px 0;

        &-link {
            color: inherit;
            text-decoration: none;
        }

        @include bp-down(medium) {
            font-size: 28px;
            line-height: 1.14;
            margin: 0 0 16px 0;
        }
    }

    &__excerpt {
        font-weight: 400;
        font-size: 18px;
        line-height: 1.67;
        margin: 0 0 48px 0;
        color: var(--excerpt-color);

        @include bp-down(medium) {
            margin: 0 0 32px 0;
            font-size: 16px;
        }
    }

    &__button {
        margin: 42px 0 0 0;

        @include bp-down(medium) {
            margin: 32px 0 0 0;
        }

        @include bp(medium) {
            margin: 82px 0 0 0;
        }

        a {
            opacity: 0.9;
        }
    }

    /* new special type */

    &--full-page {
        #{$self}__content {
            @include bp-down(medium) {
                order: 1;
            }

            &--even {
                @include bp(medium) {
                    padding-right: 24px;
                    padding-left: 40px;
                }

                @include bp-down(large) {
                    padding-left: 40px;
                }

                @include bp-down(medium) {
                    padding-right: 35px;
                    padding-left: 35px;
                }

                @include bp-down(small) {
                    padding-right: 16px;
                    padding-left: 16px;
                }
            }
        }
        #{$self}__thumb {
            @include bp-down(medium) {
                order: 2;
            }
        }
    }

    &--pin-bottom-mob {
        @include bp-down(medium) {
            #{$self}__content {
                display: flex;
                flex-direction: column;
                align-items: stretch;
                justify-content: flex-start;
            }
            #{$self}__tags {
                order: 3;
            }
            #{$self}__title {
                order: 1;
            }
            #{$self}__excerpt {
                order: 2;
            }
        }
    }
}
