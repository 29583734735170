/* ==========================================================================
   #FOOTER
   ========================================================================== */

/* stylelint-disable no-descending-specificity */
/* stylelint-disable no-duplicate-selectors */
.c-footer {
    background-color: $color-white;
    padding-bottom: 64px;

    &--simple {
        padding-top: 40px;

        @include bp-down(large) {
            padding-top: 24px;
        }
    }

    @include bp-down(large) {
        padding-bottom: 24px;
    }

    // @include bp(large) {
    // 	padding-bottom: em(214);
    // }
    &__top {
        padding: 64px 35px 0;

        @include bp-down(small) {
            padding: 24px 16px 0;
        }

        &-line {
            display: block;
            width: 100%;
            padding-top: 40px;
            border-bottom: 1px solid $border-gray-color;

            @include bp-down(large) {
                display: none;
            }
        }
    }

    &__main {
        // padding: 0 16px;
        gap: 24px;

        @include bp-down(small) {
            gap: 12px;
            padding: 32px 16px 0;
        }
        // @include bp(large) {
        // 	padding-bottom: em(120);
        // }

        &-wrapper {
            gap: 24px;

            @include bp-down(large) {
                gap: 20px;
            }
        }
    }

    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
        width: 100%;

        @include bp-down(large) {
            flex-wrap: wrap;
        }

        @include bp-down(large) {
            margin-bottom: 16px;
        }
    }

    &__title-main {
        @include bp-down(large) {
            margin-bottom: 24px;
        }
    }

    &__email-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        @include bp-down(large) {
            margin-top: 12px;
        }

        &-v2 {
            // margin-top: 32px;

            svg {
                width: 28px;
                height: 28px;
            }

            .c-footer__copy-link {
                margin-left: 8px;
            }
        }

        .c-copy-email-v2 {
            font-size: 20px;
            line-height: 1.4;
        }

        &:hover {
            .c-link {
                color: $color-black-v2;
                text-decoration: none;
            }
        }
    }

    &__img {
        width: 100%;
        height: auto;

        &-wrapp {
            display: block;
            width: 100%;
        }
    }

    &__inf {
        // padding: 0 0 40px 0;
        width: 100%;
        // min-width: 302px;
        border-bottom: 1px solid $border-gray-color;
        padding: 0 0 20px 0;

        @include bp(large) {
            // margin: 0 30px 30px 0;
            border-bottom: 0;
            width: auto;
            padding: 0;
        }

        @include bp(xlarge) {
            // min-width: 302px;
        }
    }

    &__title {
        font-style: normal;
        font-weight: 400;
        // font-size: em(22);
        font-size: 18px;
        line-height: 1.67;
        color: $color-custom-2;
        padding: 0 0 10px 0;
        margin: 0;

        @include bp(large) {
            // font-size: em(20);
            // line-height: 1.6;
            padding: 0 0 40px 0;
        }

        @include bp-down(small) {
            font-size: 16px;
        }
    }

    &__text {
        font-style: normal;
        font-weight: 400;
        // font-size: em(32);
        font-size: 20px;
        line-height: 1.4;
        // color: $color-brand-3;
        color: $color-black-v2;
        margin: 0;
        padding: 0;

        @include bp-down(small) {
            font-size: 22px;
        }
        // @include bp(large) {
        // 	font-size: em(36);
        // 	line-height: 1.61;
        // }
    }

    &__copy {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.33;
        color: $color-custom-2;
        width: 100%;
        padding-top: 64px;

        @include bp-down(large) {
            padding-top: 20px;
        }
        // color: $color-brand-24;
        // font-size: em(13);
        // line-height: 3.23;

        // @include bp(large) {
        // 	font-size: em(16);
        // 	line-height: 1.25;
        // }

        span {
            padding-left: 16px;

            @include bp(large) {
                padding-left: pct(45);
            }
        }

        a {
            color: currentColor;
            font-size: inherit;
            line-height: inherit;
            text-decoration: none;
        }
    }

    &__copy-link {
        margin-left: 14px;
        cursor: pointer;

        svg path {
            transition: $global-transition;
        }

        @include bp-down(large) {
            svg {
                width: 24px;
                height: 24px;
            }
        }
    }

    &__back {
        border: none;
        background: none;
        cursor: pointer;
        padding: 0;
        margin: 0;

        svg path {
            transition: $global-transition;
        }

        &:hover {
            svg path {
                stroke: $color-primary-600;
            }
        }
    }

    &__copy-link {
        &:hover {
            svg path {
                fill: $color-primary-600;
            }
        }
    }

    &__inf {
        &-title {
            margin-bottom: 24px;
            line-height: 1;

            @include bp-down(large) {
                margin-bottom: 16px;
            }
        }

        &-desc {
            margin-bottom: 40px;
            width: 100%;
            max-width: 500px;

            @include bp-down(large) {
                margin-bottom: 32px;
                max-width: 100%;
            }
        }
    }

    &__social {
        &-wrap {
            min-width: 264px;
        }

        &-title {
            display: block;
            margin-bottom: 40px;
            margin-top: 88px;

            @include bp-down(large) {
                margin-top: 30px;
                margin-bottom: 16px;
            }
        }
    }

    &__desc {
        @include bp(large) {
            max-width: 322px;
        }

        &-title {
            margin-bottom: 20px;

            @include bp-down(large) {
                margin-bottom: 16px;
            }
        }

        &-desc {
            margin-bottom: 40px;
            width: 100%;
            max-width: 500px;

            @include bp-down(large) {
                margin-bottom: 32px;
                max-width: 100%;
            }
        }
    }

    &__menus {
        margin-left: auto;
        gap: 20px 11px;
        width: 100%;

        @include bp(large) {
            gap: 24px;
        }
    }
}
