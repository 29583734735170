.c-box-shadow {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    padding: 32px 24px;
    border-radius: 8px;
    box-shadow: 0 15px 30px rgba(51, 37, 112, 0.08);
    background-color: $color-custom-1;

    @include bp-down(medium) {
        padding: 24px 16px;
    }

    &__title {
        font-size: 20px;
        margin-bottom: 20px;

        @include bp-down(medium) {
            font-size: 14px;
        }
    }

    &__desc {
        margin-bottom: 20px;

        @include bp-down(medium) {
            margin-bottom: 0;
            font-size: 14px;
        }
    }
}
