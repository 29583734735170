.b-box-number {
    position: relative;
    padding: 50px 90px;

    @include bp-down(large) {
        padding: 20px 0;
    }

    &__wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: stretch;
        flex-wrap: wrap;

        &--header {
            margin-bottom: 56px;

            @include bp-down(medium) {
                display: block;
            }
        }

        &--boxes {
            align-items: stretch;
            justify-content: space-between;
        }

        &--reverse {
            flex-direction: row-reverse;
        }
    }

    &__number {
        &-wrap {
            width: 40%;

            &--align-right {
                text-align: right;

                @include bp-down(medium) {
                    text-align: left;
                }
            }

            @include bp-down(medium) {
                width: 100%;
                margin-bottom: 16px;
            }
        }
    }

    &__header {
        width: 60%;

        @include bp-down(medium) {
            width: 100%;
        }
    }

    &__title {
        margin-bottom: 20px;

        @include bp-down(medium) {
            margin-bottom: 12px;
            font-size: 22px;
            font-weight: 500;
        }
    }

    &__box-header {
        width: 100%;
        margin-bottom: 24px;

        @include bp-down(medium) {
            margin-bottom: 16px;
            font-size: 18px;
            font-weight: 500;
        }
    }

    &__wrap-single-box {
        width: 32.4%;
        display: flex;

        @include bp-down(medium) {
            width: 100%;
        }
    }

    &__btn {
        margin-top: 40px;

        @include bp-down(medium) {
            margin-top: 20px;
        }
    }
}
