/* ==========================================================================
   #ALIGN
   ========================================================================== */

.u-float-left {
    float: left;
}

.u-float-right {
    float: right;
}

.u-clear {
    clear: both;
}

.u-text-center {
    text-align: center;
}

.u-text-left {
    text-align: left;
}

.u-text-right {
    text-align: right;
}
