.c-podcast-box {
    $self: &;

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    margin-bottom: 32px;
    border-radius: 8px;
    background-color: $color-dark-box;
    overflow: hidden;
    text-decoration: none;

    &--style {
        &-light {
            background-color: $color-custom-1;
            box-shadow: 0 15px 30px rgba(51, 37, 112, 0.08);
        }
    }

    &--mobile-v2 {
        @include bp-down(medium) {
            flex-wrap: wrap;
            #{$self}__img {
                width: 64px;
                border-radius: 4px;

                &-wrapper {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    justify-content: stretch;
                    width: 100%;
                    margin-bottom: 16px;
                }
            }
            #{$self}__title {
                display: none;

                &--mobile-v2 {
                    display: block;
                    width: calc(100% - 64px);
                    padding-left: 16px;
                }
            }
            #{$self}__data {
                width: 100%;
                padding-left: 0;
                display: block;

                &-link-wrap {
                    display: block;
                    width: 100%;
                    margin-top: 29px;
                    text-align: right;

                    .c-link-arrow {
                        font-size: 16px;

                        svg {
                            width: 20px;
                        }
                    }
                }
            }
        }
    }

    @include bp-down(medium) {
        padding: 24px 16px;
        margin-bottom: 16px;
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        @include bp-down(medium) {
            height: auto;
        }

        &-wrapper {
            display: block;
            width: 200px;
            height: auto;

            @include bp-down(medium) {
                width: 64px;
                border-radius: 4px;
            }
        }
    }

    &__data {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 43px 40px;
        width: calc(100% - 200px);

        @include bp-down(medium) {
            width: calc(100% - 64px);
            padding: 0 0 0 24px;
        }

        &-inf {
            padding-right: 60px;

            @include bp-down(medium) {
                padding-right: 0;
            }
        }

        &-link-wrap {
            @include bp-down(medium) {
                display: none;
            }
        }
    }

    &__title {
        margin-bottom: 24px;

        &--mobile-v2 {
            display: none;
        }

        @include bp-down(medium) {
            margin-bottom: 16px;
            // display: none;

            &--mobile-v2 {
                display: block;
            }
        }
    }
}
