.s-not-found-page {
    position: relative;
    min-height: calc(100vh - 98px);
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 50px 0;

    &__content-center {
        position: relative;
        text-align: center;
        width: 100%;
        max-width: 550px;
        margin: 0 auto;
        padding: 130px 0;
    }

    &__title {
        margin-bottom: 24px;
    }

    &__text {
        position: relative;
        margin: 20px 0 48px;
        text-align: center;
    }
}
