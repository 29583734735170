.c-box-author {
    position: relative;

    &__big-wrapp {
        display: inline-block;
    }

    &__img {
        width: 166px;
        height: auto;

        @include bp-down(medium) {
            width: 106px;
            height: auto;
        }
    }

    &__box-title {
        margin-bottom: 8px;
    }

    &__box-author {
        margin-top: 8px;
        text-align: center;
    }
}
