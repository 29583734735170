.c-copy-email {
    position: relative;

    &:hover {
        svg path {
            fill: $color-primary-600;
        }
    }

    &.active,
    &.error {
        &::after {
            font-size: 16px;
            display: inline-block;
            position: absolute;
            bottom: -25px;
            left: -25px;
        }
    }

    &.active {
        &::after {
            color: $color-primary-600;
            content: attr(data-email-active);
        }
    }

    &.error {
        &::after {
            color: $color-brand-11;
            content: attr(data-email-error);
        }
    }
}
