/* stylelint-disable no-descending-specificity */
/* stylelint-disable  selector-class-pattern */
/* stylelint-disable selector-no-redundant-nesting-selector */
.c-breadcrumbs {
    margin: 13px 0;
    color: $color-black-v2;
    font-size: 14px;
    font-weight: 400;

    &__separate {
        display: inline-block;
        vertical-align: middle;
        margin: 0 4px;
        color: $color-black-v2;
    }

    span,
    a {
        display: inline;
        vertical-align: middle;
    }

    a {
        transition: $global-transition;
        font-weight: 400;
        text-decoration: none;

        &:hover {
            color: $color-brand-22;
        }
    }

    .breadcrumb_last {
        font-weight: 600;
    }

    > span {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        flex-wrap: wrap;
        justify-content: flex-start;

        @include bp-down(small) {
            display: inline-block;
            width: 100%;
        }
    }
}
