.l-realization {
    $self: &;

    position: relative;
    padding: 120px 0 80px;

    @include bp-down(medium) {
        padding: 77px 0 40px;

        > .o-wrapper {
            padding: 0;
        }
    }

    &__wrapper {
        padding: 0;
        max-width: 100%;
    }

    &__first-secion {
        margin-bottom: 105px;

        @include bp-down(medium) {
            margin-bottom: 64px;
        }
    }
}
