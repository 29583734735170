.b-heading-image {
    $self: &;

    color: $color-black;
    font-style: normal;

    &__title {
        font-weight: 600;
        font-size: em(50);
        line-height: 1.4;
        text-align: center;
        margin: 0;
        padding: em(13.44) 0 em(16.32) 0;

        @include bp(large) {
            max-width: 910px;
            margin-left: auto;
            margin-right: auto;
            font-size: em(60);
            line-height: 1.37;
        }
    }

    &__banner {
        text-align: center;

        @include bp-down(medium) {
            padding: 0 16px;
        }

        img {
            width: 100%;
        }
    }

    &__img {
        &--desktop {
            @include bp-down(medium) {
                display: none;
            }
        }

        &--mobile {
            display: none;

            @include bp-down(medium) {
                display: block;
            }
        }

        @include bp-down(medium) {
            border-radius: 8px;
        }
    }

    &--full-page {
        @include bp-down(medium) {
            #{$self}__img {
                border-radius: 0;
            }

            #{$self}__banner {
                padding: 0;
            }
        }
    }
}
