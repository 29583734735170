.b-contact-photo {
    background-color: $color-white;

    h4 {
        font-style: normal;
        line-height: 1;
        font-weight: 700;
        font-size: em(80);
        background: linear-gradient(270.09deg, $color-brand-1 11.67%, $color-brand-2 94.19%);
        -webkit-text-fill-color: transparent;
        background-clip: text;
        // text-fill-color: transparent;
        margin: 0 0 42px 0;

        @include bp(large) {
            font-size: em(150);
            margin: 0 0 102px 0;
        }
    }

    p {
        font-style: normal;
        font-weight: 400;
        color: $color-medium-grey;
        font-size: 22px;
        line-height: 1.91;
        margin: 0 0 5px 0;

        @include bp(large) {
            font-size: em(28);
            line-height: 1.5;
            margin: 0 0 26px 0;
        }
    }

    a {
        font-style: normal;
        text-decoration-line: underline;
        color: $color-brand-3;
        font-size: em(36);
        line-height: 1.17;
        margin: 0 0 41px 0;
        display: inline-block;

        @include bp(large) {
            font-weight: 400;
            font-size: em(60);
            line-height: 1;
            margin: 0 0 112px 0;
        }
    }

    img {
        max-width: 100%;
    }
}
