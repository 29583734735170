.c-uniwersal-content {
    $heading-base: 37;

    color: $color-custom-2;

    @for $i from 1 through 5 {
        h#{$i} {
            margin-bottom: 20px;
            font-size: em($heading-base);
            color: $color-black-v2;

            @include bp-down(medium) {
                font-size: em($heading-base - 2);
                font-weight: 500;
            }
        }
        $heading-base: $heading-base - 5;
    }

    p {
        margin-bottom: 40px;
        color: $color-custom-2;

        @include bp-down(medium) {
            margin-bottom: 20px;
        }
    }

    blockquote {
        quotes: none;
        background: $color-white;
        box-shadow: 0 15px 30px rgba(51, 37, 112, 0.08);
        border-radius: 8px;
        padding: 40px;
        font-weight: 400;
        text-align: left;
        font-style: normal;
        color: $color-custom-2;
        margin: 64px 0;

        @include bp-down(medium) {
            margin: 32px 0;
            padding: 16px;
        }

        p {
            margin: 0;
        }
    }

    img {
        margin: 0;
        border-radius: 8px;
    }

    ul,
    ol {
        margin-left: 30px;

        @include bp-down(medium) {
            margin-left: 20px;
        }

        li {
            margin: 0 0 3px 0;
            font-style: normal;
            font-size: 18px;
            line-height: 1.67;

            &::marker {
                font-size: 8px;
            }

            @include bp-down(medium) {
                font-size: 16px;
            }
        }
    }

    a {
        color: $color-medium-grey;
        transition: $global-transition;

        &:hover {
            color: $color-brand-22;
        }
    }

    strong {
        font-weight: 600;
    }

    .wp-block-image {
        margin: 64px 0;

        @include bp-down(medium) {
            margin: 32px 0;
        }

        &,
        .size-full {
            &,
            img {
                width: 100%;
            }
        }
    }
}
