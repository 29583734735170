.c-mega-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    // height: 100vh;
    height: auto;
    background-color: $color-custom-1;
    z-index: 99999;
    overflow-y: auto;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-100%);
    transition: transform 0.3s linear, visibility 0.3s linear, opacity 0.3s linear;
    transition-delay: 0s, 0.3s, 0.3s;

    @include bp-down(large) {
        height: 100vh;
    }

    &.active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        transition: transform 0.3s linear, visibility 0.3s linear, opacity 0.3s linear;
        // transition-delay: 0s,0.3s, 0.3s;
    }

    &__header {
        padding: 40px 0 24px;
        // border-bottom: 1px solid #d5d5d5;

        @include bp-down(large) {
            padding: 16px;
        }
    }

    &__wrapper {
        &--header {
            @include bp-down(large) {
                padding: 0;
            }
        }
    }

    &__body {
        &-wrapper {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: stretch;
            padding-top: 48px;

            @include bp-down(large) {
                padding: 24px 16px;
                flex-wrap: wrap;
            }
        }
    }

    &__col {
        &--fixed {
            width: 300px;

            @include bp-down(large) {
                display: none;
            }
        }

        &--right {
            text-align: right;

            @include bp-down(large) {
                display: block;
                width: 100%;
            }
        }

        &--centered {
            flex-grow: 1;
            text-align: center;

            @include bp-down(large) {
                display: none;
            }
        }

        &--half {
            width: 50%;

            &-big {
                width: 58%;
            }

            &-small {
                width: 42%;
            }

            @include bp-down(large) {
                width: 100%;
            }
        }
    }

    &__close {
        padding: 0;
        margin: 0;
        border: none;
        background-color: transparent;

        @include bp-down(large) {
            svg {
                width: 28px;
                height: 28px;
            }
        }

        &:hover {
            opacity: 0.7;
        }

        &:focus {
            outline: none;
        }
    }

    &__menus-wrapper {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: space-between;
        flex-wrap: wrap;
        // padding-left: 110px;

        @include bp-down(xlarge) {
            padding-left: 0;
        }
    }

    &__single-menu {
        width: 50%;
        margin-bottom: 80px;

        @media (max-height: 778px) {
            margin-bottom: 40px;
        }

        @include bp-down(xlarge) {
            margin-bottom: 40px;
        }

        @include bp-down(large) {
            margin-bottom: 32px;
        }

        &--fluid {
            width: 100%;
        }
    }

    &__inf {
        &-title {
            margin-bottom: 20px;
            line-height: 1;

            @include bp-down(large) {
                margin-bottom: 16px;
            }
        }

        &-desc {
            margin-bottom: 40px;
            width: 100%;
            max-width: 500px;

            @include bp-down(large) {
                margin-bottom: 32px;
                max-width: 100%;
            }
        }

        // &-social-title {
        // 	display: block;
        // 	margin-bottom: 40px;

        // 	@media (max-height: 778px) {
        // 		margin-bottom: 20px;
        // 	}

        // 	@include bp-down(xlarge) {
        // 		margin-bottom: 20px;
        // 	}
        // }

        &-social-wrap {
            @include bp-down(large) {
                display: none;
            }
        }

        &-switcher-wrap {
            margin-top: 100px;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;

            @media (max-height: 778px) {
                margin-top: 40px;
            }

            @include bp-down(xlarge) {
                margin-top: 40px;
            }

            @include bp-down(large) {
                margin: 0;
            }
        }
    }

    &__switcher-lang {
        // width: 300px;
        width: 175px;
        margin-bottom: 9px;
        // position: absolute;
        // bottom: 0;
        // right: 0;
        @include bp-down(xlarge) {
            width: auto;
        }

        @include bp-down(large) {
            position: absolute;
            top: 20px;
            left: 12px;
            margin: 0;
            width: auto;
        }
    }
}
