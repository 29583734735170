/* ==========================================================================
   #COMMENT
   ========================================================================== */

.c-comment {
    margin-bottom: 1em;
}

.c-comment__author {
    font-size: 1rem;
    margin-bottom: 0.25em;
}

.c-comment__moderation {
    font-style: italic;
    margin-bottom: 0.5em;
}
