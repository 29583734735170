/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav {
    display: block;
    margin: 0;
    padding: 0;

    &__title {
        margin-bottom: 40px;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.67;
        color: $color-custom-2;

        @media (max-height: 778px) {
            margin-bottom: 15px;
        }

        @include bp-down(xlarge) {
            margin-bottom: 15px;
        }

        @include bp-down(medium) {
            font-size: 16px;
        }
    }

    &__item {
        display: block;
        margin-bottom: 20px;

        @media (max-height: 778px) {
            margin-bottom: 10px;
        }

        @include bp-down(xlarge) {
            margin-bottom: 10px;
        }

        @include bp-down(medium) {
            margin-bottom: 16px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__link {
        font-size: 28px;
        line-height: 1.43;
        text-decoration: none;
        color: $color-black-v2;
        transition: $global-transition;

        @include bp-down(medium) {
            font-size: 22px;
            line-height: 1.1;
        }

        &:hover {
            color: $color-primary-600;
        }
    }

    &__dropdown {
        column-count: 2;
        column-gap: 11px;
        list-style: none;
        margin: 15px 0 0 0;
        padding: 0;
    }

    &__subitem {
        margin: 0 0 8px 0;
        padding: 0;
    }

    &__sublink {
        font-weight: 400;
        font-size: 18px;
        line-height: 1.22;
        text-decoration: none;

        &:hover {
            color: $color-primary-600;
        }
    }
}
