.b-cards-slide {
    &--dark {
        --cards-slide-box-bg: #{$color-custom-1};
        --cards-slide-svg: #{$color-custom-1};
        --cards-slide-svg-hover: #{$color-brand-4};
        --cards-slide-link-hover: #{rgba($color-brand-4, 0.25)};
    }

    &--light {
        --cards-slide-box-bg: #{$color-custom-1};
        --cards-slide-svg: #{$color-black-v2};
        --cards-slide-svg-hover: #{$color-brand-22};
        --cards-slide-link-hover: #{$color-brand-15};
    }

    &__wrapper {
        padding-right: 0;
    }

    &__heading {
        margin-bottom: 48px;
        // padding-top: em(28.8);
        // border-top: 1px solid rgba($color-white, 0.15);
        // font-weight: 600;
        // font-size: em(46);
        // line-height: 1.37;
        // color: $color-white;
        // margin: em(22.13) 0 em(9.87) 0;

        // @include bp(large) {
        // 	font-size: em(60);
        // }
    }

    &__swiper {
        .swiper-wrapper {
            box-sizing: border-box;
        }
    }

    &__slide {
        height: auto;
    }

    &__box {
        background-color: var(--cards-slide-box-bg);
        border-radius: 8px;
        padding: 40px;
        height: 100%;
        text-decoration: none;

        &--link {
            &:hover {
                box-shadow: 10px 10px 10px var(--cards-slide-link-hover);
            }
        }

        // padding: em(35) em(43);
        // min-height: em(426);
        // height: calc(100% - em(70));

        // @include bp(large) {
        // 	min-height: em(526);
        // 	padding: em(40) em(48);
        // 	height: calc(100% - em(80));
        // }

        @include bp-down(medium) {
            padding: 16px;
        }

        img {
            max-height: 52px;
            width: auto;
        }
    }

    &__box-heading {
        font-weight: 600;
        font-size: 24px;
        line-height: 1.25;
        // color: rgba($color-brand-3, 0.9);
        color: $color-black-v2;
        margin: 0 0 24px 0;

        @include bp-down(medium) {
            margin: 0 0 16px 0;
            font-size: 22px;
        }
    }

    &__box-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 1.6;
        margin: 0 0 26px 0;
        color: rgba($color-black-v2, 0.9);

        @include bp-down(medium) {
            margin: 0 0 30px 0;
            font-size: 14px;
            line-height: 1.57;
        }

        // @include bp(large) {
        // 	font-size: em(26);
        // }
    }

    &__box-logos {
        justify-self: flex-end;
        margin-top: auto;
        padding: 16px 0;
        gap: 20px 40px;
    }

    &__arrow-wrapper {
        padding-top: 48px;
        text-align: center;
        margin-left: -35px;

        @include bp-down(medium) {
            margin-left: -16px;
        }
    }

    &__arrow {
        position: relative;
        background-color: transparent;
        margin: 0;
        border: none;
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;

        &:focus {
            display: inline-block;
            outline: none;
        }

        &--left {
            margin-right: 50px;
        }

        &--right {
            margin-left: 50px;
        }

        svg path {
            transition: $global-transition;
            stroke: var(--cards-slide-svg);
        }

        &:hover {
            svg path {
                stroke: var(--cards-slide-svg-hover);
            }
        }
    }
}
