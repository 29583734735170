/* stylelint-disable no-descending-specificity */
.c-link-arrow {
    position: relative;
    display: inline-flex;
    font-size: 20px;
    padding: 0 2px 3px;
    font-weight: var(--c-link-arrow-wieght, 600);
    line-height: 24px;
    color: $color-custom-1;
    text-decoration: none;
    transition: $global-transition;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: var(--c-link-arrow-height, 3px);
        background-color: $color-brand-22;
        transition: $global-transition;
    }

    &--thin {
        --c-link-arrow-height: 2px;
        --c-link-arrow-wieght: 500;
    }

    svg {
        margin-left: 8px;
        transform: translateX(0);
        transition: $global-transition;

        path {
            transition: $global-transition;
        }
    }

    &:hover {
        color: rgba($color-custom-1, 0.8);

        svg {
            transform: translateX(-3px);

            path {
                stroke: rgba($color-custom-1, 0.8);
            }
        }
    }

    &__size {
        &-small {
            font-size: 16px;

            svg {
                width: 20px;
            }
        }
    }

    &__style {
        &-dark {
            color: var(--c-link-arrow-style-dark-color, $color-black-v2);

            svg path {
                stroke: var(--c-link-arrow-style-dark-color, $color-black-v2);
            }

            &:hover {
                color: var(--c-link-arrow-style-dark-color-hover, rgba($color-black-v2, 0.8));

                svg path {
                    stroke: var(--c-link-arrow-style-dark-color-hover, rgba($color-black-v2, 0.8));
                }
            }
        }
    }
}
