.b-cards {
    color: $color-brand-3;
    padding: em(36) 0;
    $gap: 24px;

    @include bp(large) {
        padding: em(131) 0 em(100) 0;
    }

    &__wrapper {
        &--grid-four {
            gap: $gap;
        }
    }

    &__heading {
        // font-weight: 600;
        // font-size: em(54);
        // line-height: 1.37;
        // color: $color-brand-3;
        margin: 0 0 40px 0;
        text-align: left;

        @include bp-down(medium) {
            margin: 0 0 22px 0;
        }
        // @include bp(large) {
        // 	font-size: em(60);
        // 	margin: 0 0 em(14.66) 0;
        // }s
    }

    &__box {
        background-color: $color-white;
        border-radius: 8px;
        padding: em(32) em(24);
        margin: 0 0 0 0;

        @include bp(large) {
            padding: em(50);
            margin: 0 0 0 0;
            width: 48.5%;
        }

        img {
            align-self: flex-start;
            margin: 0 em(32) em(24) 0;
            width: 100%;
            max-width: 66px;

            @include bp(large) {
                max-width: 80px;
                margin: 0 em(32) em(24) 0;
            }
        }

        h4 {
            font-weight: 700;
            opacity: 0.9;
            font-size: em(30);
            line-height: 1.47;
            margin: 0 0 10px 0;

            @include bp(large) {
                margin: 0 0 em(7.11) 0;
                font-size: em(36);
                line-height: 1.22;
            }
        }

        p {
            color: $color-brand-7;
            font-weight: 400;
            opacity: 0.9;
            font-size: em(22);
            line-height: 1.73;
            margin: 0 0 22px 0;

            @include bp(large) {
                margin: 0 0 em(30.77) 0;
                font-size: em(26);
                line-height: 1.54;
            }
        }

        a:not(.c-link-arrow) {
            font-weight: 600;
            font-size: em(20);
            line-height: 1.25;
            color: $color-brand-3;
            text-decoration: none;
            transition: $global-transition;
            margin-top: auto;

            @include bp(large) {
                font-size: em(24);
            }

            span {
                margin-left: 20px;
                transition: $global-transition;
            }

            &:hover {
                span {
                    margin-left: 15px;
                }
            }
        }

        &--icon {
            h4 {
                font-weight: 500;
            }

            p {
                color: $color-brand-24;
            }
        }

        &--margin-grid-second {
            @include bp(large) {
                margin: 119px 0 0 0;
            }
        }

        &--margin-grid {
            // padding: em(40) em(24);
            padding: 40px;
            box-shadow: 0 15px 30px rgba(51, 37, 112, 0.08);
            border-radius: 8px;

            @include bp-down(medium) {
                padding: 24px;
            }

            p {
                margin: 0;
                // font-size: em(24);
                font-size: 20px;
                line-height: 1.4;
                opacity: 0.9;
                color: $color-custom-2;

                @include bp-down(medium) {
                    margin: 0 0 40px;
                    font-size: 16px;
                    line-height: 1.6;
                }
            }

            h4 {
                margin: 0 0 20px;
                font-size: 24px;
                line-height: 1.25;

                @include bp-down(medium) {
                    margin: 0 0 16px;
                    font-size: 20px;
                    line-height: 1.2;
                }
            }

            @include bp(large) {
                // padding: em(56);

                &:nth-child(n + 3) {
                    margin: 45px 0 0 0;
                }
            }

            &:first-child {
                @include bp(large) {
                    margin-bottom: 90px;
                }
            }
        }

        &--margin-grid-even {
            @include bp(large) {
                &:nth-child(n + 1) {
                    margin: -45px 0 90px 0;
                }
            }
        }

        &--grid-four {
            flex: 1 1 calc(100% / 4 - $gap);

            @include bp-down(large) {
                flex: 1 1 calc(100% / 2 - $gap);
            }

            @include bp-down(medium) {
                flex: 1 1 100%;
            }
        }

        &--v2 {
            padding: 32px 24px;
            box-shadow: 0 15px 30px rgba(51, 37, 112, 0.08);
            border-radius: 8px;
            background-color: $color-custom-1;

            h4 {
                min-height: 60px;
                margin-bottom: 12px;
                font-size: 24px;
                line-height: 1.25;
                color: $color-black-v2;

                @include bp-down(medium) {
                    font-size: 22px;
                    line-height: 1.18;
                    min-height: auto;
                    margin-bottom: 16px;
                }
            }

            p {
                font-size: 16px;
                line-height: 1.5;
                color: $color-custom-2;
                opacity: 0.9;

                @include bp-down(medium) {
                    margin-bottom: 30px;
                }
            }
        }

        &-link {
            &-wrapper {
                margin-top: auto;
            }
        }

        &--title-height-small {
            h4 {
                margin-bottom: 16px;
                min-height: auto;
            }
        }

        &-text-wrap {
            ul {
                margin: 0;
                padding-left: 22px;

                li {
                    font-size: 16px;
                    line-height: 1.5;
                    color: $color-custom-2;
                    opacity: 0.9;

                    &::marker {
                        font-size: 8px;
                    }
                }
            }
        }
    }

    &__counter {
        display: block;
        margin: 0 0 3px 0;
        font-weight: 400;
        // font-size: em(26);
        font-size: 28px;
        line-height: 1.4;
        // line-height: 0.81;
        color: $color-brand-22;
        // opacity: 0.9;
        @include bp-down(medium) {
            margin: 0;
            font-size: 22px;
            line-height: 1.18;
        }
    }
}
