.s-simple-centred-se {
    padding: 120px 0;
    text-align: center;

    @include bp-down(medium) {
        padding: 40px 0 64px;
        text-align: left;

        &--mobile-centered {
            text-align: center;
        }
    }

    &__desc {
        margin-bottom: 48px;

        @include bp-down(medium) {
            margin-bottom: 40px;
        }
    }

    &__title {
        margin-bottom: 24px;

        @include bp-down(medium) {
            margin-bottom: 16px;

            br {
                display: none;
            }
        }
    }

    &__img {
        width: 100%;
        height: auto;
        border-radius: 8px;

        &-wrap {
            display: block;
            width: 100%;
            max-width: 90%;
            margin: 0 auto 48px auto;

            @include bp-down(medium) {
                max-width: 100%;
                margin: 0 auto 32px auto;
            }
        }
    }
}
