.b-faq {
    position: relative;
    padding: 30px 0;

    &__questions {
        margin: 0 auto;
        width: 70%;

        @media (max-width: 1600px) {
            width: 100%;
            max-width: 700px;
        }
    }
}
