/* stylelint-disable */
.b-text-links-image {
	margin-bottom: 64px;

	@include bp(large) {
		margin-bottom: 120px;
	}

	&,
	a:not(.c-btn) {
		text-decoration: none;
		font-style: normal;
		color: $color-brand-3;
	}

	&__wrap-heading-mobile {
		display: none;

		@include bp-down(large) {
			display: block;
		}
	}

	&__heading {
		font-weight: 600;
		font-size: 40px;
		line-height: 1.2;
		// font-size: em(36);
		// line-height: 2.28;
		margin: 0 0 24px 0;
		color: $color-black-v2;

		@include bp-down(large) {
			&--hidden-mobile {
				display: none;
			}
		}

		@include bp-down(medium) {
			font-size: 28px;
			line-height: 1.14;
			margin: 0 0 16px 0;
		}

		// @include bp(large) {
		// 	font-size: em(60);
		// 	line-height: 1.37;
		// 	margin: 0 0 18px 0;
		// }
	}

	&__text {
		p {
			// margin: 0 0 em(16) 0;
			margin: 0 0 16px 0;
			font-weight: 400;
			font-size: 18px;
			line-height: 1.67;
			// font-size: em(24);
			// color: $color-brand-24;
			color: $color-custom-2;

			// @include bp(large) {
			// 	font-size: em(30);
			// 	line-height: 1.67;
			// }

			strong {
				font-weight: 600;
			}

			&:last-of-type {
				margin: 0 0 48px 0;

				@include bp-down(medium) {
					margin-bottom: 16px;
				}
			}
		}

		p + a {
			margin-top: 0;
		}

		ol {
			list-style: none;
			padding: 0 0;
			margin: 0;

			> li {
				font-weight: 500;
				font-size: 16px;
				line-height: 1.25;
				letter-spacing: 0.5px;
				padding: 18px 0;
				margin: 0;
				color: $color-brand-3;
				transition: $global-transition;
				border-bottom: 1px solid $color-brand-32;
				display: flex;
				align-items: flex-start;
				justify-content: flex-start;

				&::before {
					content: '\2022';
					display: block;
					flex-direction: row;
					padding: 0 15px;
				}
			}
		}

		ul {
			list-style: none;
			padding: 0;
			margin: 0;

			> li {
				margin: 0;
				padding: 0;
				font-size: 16px;
				line-height: 1.25;

				@include bp-down(medium) {
					font-size: 14px;
				}

				> a {
					color: $color-brand-3;
					font-weight: 500;
					// font-size: em(20);
					font-size: 16px;
					line-height: 1.25;
					display: flex;
					letter-spacing: 0.5px;
					align-items: center;
					justify-content: space-between;
					transition: $global-transition;
					border-bottom: 1px solid $color-brand-5;
					// padding: em(21.6) 0;
					padding: 18px 0;

					@include bp-down(medium) {
						font-size: 14px;
					}

					// @include bp(large) {
					// 	font-size: em(24);
					// }

					&::after {
						content: '';
						display: inline-block;
						vertical-align: middle;
						background: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.25 12.8943H20.75' stroke='%230A0A0A' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14 6.14429L20.75 12.8943L14 19.6443' stroke='%230A0A0A' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat center center;
						width: 24px;
						height: 24px;
						margin-left: 15px;
						transition: $global-transition;

						@include bp-down(medium) {
							width: 20px;
							height: 20px;
						}

						// content: url('~assets/images/text_links_image_arrow_right_small.svg');

						// @include bp(large) {
						// 	content: url('~assets/images/text_links_image_arrow_right.svg');
						// }
					}

					&:hover {
						// border-color: $color-brand-3;
						border-color: $color-brand-22;

						&::after {
							transform: translateX(-5px);
						}
					}
				}
			}
		}
	}

	&__content {
		&--row {
			@include bp(large) {
				padding: 45px vw(100) 45px 0;
			}
		}

		&--row-reverse {
			@include bp(large) {
				padding: 45px 0 45px vw(100);
			}
		}

		&--podcast {
			max-width: 692px;
		}
	}

	&__figure {
		@include bp-down(large) {
			margin: 0 0 em(24) 0;
		}

		@include bp-down(medium) {
			margin: 0 0 16px 0;
		}

		img {
			width: 100%;
			border-radius: 8px;
		}

		&--podcast {
			max-width: 438px;
		}
	}

	&__figure,
	&__content {
		@include bp(large) {
			flex: 1;
		}
	}

	&__btn {
		margin-top: 24px;

		@include bp(large) {
			margin-top: 48px;
		}
	}

	.image-60 {
		display: flex;
		justify-content: center;
		img {
			width: 60%!important;
		}
	}
}

/* stylelint-enable */
