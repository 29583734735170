/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
    // padding: em(38) 0 15px 0;
    padding: 24px 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 100;
    transform: translateY(0);
    transition: $global-transition;
    // overflow: hidden;

    @include bp(large) {
        // padding: em(60) 0 16px 0;
    }

    @include bp-down(large) {
        padding: 16px 0;
    }

    &.scrolled {
        padding: 24px 0;
        background: $color-black-v2;
        backdrop-filter: blur(2px);

        @include bp(large) {
            .c-header__logo-link {
                position: relative;
                display: inline-block;
            }

            .c-header__logo-image {
                &--desktop {
                    opacity: 0;
                }

                &--mobile {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 40px;
                    height: 40px;
                }
            }
        }

        @include bp-down(large) {
            padding: 16px 0;
        }
    }

    &.header-hidden {
        transform: translateY(-100%);

        &.open-menu {
            transform: translateY(0);
        }
    }

    &--type {
        &-dark {
            &.scrolled {
                background: $color-black-v2;
                backdrop-filter: blur(2px);
            }
        }

        &-light {
            &.scrolled {
                background-color: $color-white;
            }
        }
    }

    &__logo {
        &-image {
            &--mobile {
                display: none;
                transition: none;
            }
        }

        @include bp-down(large) {
            max-width: 60px;

            &-image {
                &--mobile {
                    display: inline-block;
                }

                &--desktop {
                    display: none;
                }
            }
        }
    }

    &__hamburger {
        // margin-left: auto;
        background: none;
        border: none;
        // padding: 10px 0;
        padding: 0;
        margin: 0;
        max-width: 40px;

        svg {
            transition: $global-transition;
        }

        &:hover {
            svg {
                opacity: 0.5;
            }
        }

        @include bp(large) {
            max-width: 100%;
        }
    }

    &__col {
        &--left {
            // width: 40%;
            // width: 300px;
            width: 360px;

            @include bp-down(large) {
                width: 50px;
            }
        }

        &--right {
            // width: 300px;
            width: 360px;
            justify-content: flex-end;

            @include bp-down(large) {
                width: 50px;
            }
        }

        &--desktop {
            gap: 0 48px;
        }

        &--middle {
            flex-grow: 1;
            text-align: center;
        }
    }

    &__text-simple {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 1.4;
        letter-spacing: 1px;
        color: $color-black-v2;

        &--white {
            color: $color-custom-1;
        }

        &--colored {
            color: $color-brand-4;
        }
    }

    &__text-home-url {
        text-decoration: none;

        &:focus {
            outline: none;
        }
    }
}
