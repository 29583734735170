.c-footer-menu {
    width: 100%;

    &--static {
        max-width: calc(50% - 11px);

        @include bp-down(large) {
            padding-top: 20px;
        }
    }

    &--fluid {
        max-width: 100%;

        @include bp-down(large) {
            border-top: 1px solid $border-gray-color;
            border-bottom: 1px solid $border-gray-color;
            padding-top: 20px;
            padding-bottom: 4px;
        }
    }

    @include bp(large) {
        &--static {
            max-width: 150px;
        }

        &--fluid {
            max-width: 322px;
        }
        // margin-right: 30px;
    }

    &__title {
        font-style: normal;
        font-weight: 400;
        // font-size: em(22);
        // line-height: 1.91;
        // color: $color-brand-23;
        font-size: 18px;
        line-height: 1.67;
        color: $color-custom-2;
        padding: 29px 0;
        border-bottom: 1px solid $color-border;
        cursor: pointer;

        &.active {
            span {
                transform: rotate(-180deg);
            }
        }

        @include bp(large) {
            // font-size: em(20);
            // line-height: 1.6;
            margin: 0 0 36px 0;
            padding: 0;
            border-bottom: 0;
            cursor: auto;
        }

        @include bp-down(small) {
            padding: 20px 0;
            font-size: 16px;
        }
    }

    &__links {
        list-style: none;
        padding: 0;
        margin: 0;
        // display: none;

        &.active {
            padding: 29px 0;
            display: block;
            border-bottom: 1px solid $color-border;
        }

        @include bp(large) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            // display: block;
            // columns: 2;
            // column-gap: 80px;
        }

        > li {
            margin: 0 0 16px 0;

            @include bp(large) {
                margin: 0 0 24px 0;
                // width: 49%;
            }

            > a {
                text-decoration: none;
                font-style: normal;
                font-weight: 400;
                // font-size: em(26);
                font-size: 28px;
                line-height: 1.43;
                // color: $color-brand-3;
                color: $color-black-v2;
                transition: $global-transition;

                @include bp-down(xlarge) {
                    font-size: 22px;
                    line-height: 1.22;
                }

                &:hover {
                    color: $color-primary-600;
                }
            }
        }
    }

    &__sub {
        &-menu {
            column-count: 2;
            column-gap: 24px;
            list-style: none;
            margin: 20px 0 0 0;
            padding: 0;

            @include bp(large) {
                margin: 15px 0 0 0;
            }
        }

        &-item {
            margin: 0 0 12px 0;
            padding: 0;
        }

        &-link {
            font-weight: 400;
            text-decoration: none;
            font-size: 18px;
            line-height: 1.22;

            @include bp(xlarge) {
                font-size: 20px;
                line-height: 1.4;
            }

            &:hover {
                color: $color-primary-600;
            }
        }
    }
}
