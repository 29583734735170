.b-carousel-videos {
    &--dark {
        --color-title: #{$color-custom-1};
    }

    &--light {
        --color-title: #{$color-black-v2};
    }

    &__slide {
        text-align: center;
    }

    &__heading {
        margin-bottom: 40px;
    }

    &__video {
        margin-bottom: 16px;
        transition: $global-transition;

        &.opacity {
            opacity: 0.5;
        }
    }

    &__title {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 1.25;
        margin-bottom: 27px;
        color: var(--color-title);

        @include bp-down(medium) {
            font-size: 18px;
        }
    }

    &__link {
        span {
            font-weight: 500;
        }
    }
}
