/*
  Project: Zima
  Author: WebCrafters Studio
 */
@import '~swiper/swiper';
@import '~swiper/modules/navigation/navigation';
@import '~swiper/modules/pagination/pagination';
@import '~swiper/modules/autoplay/autoplay';
@import '~aos/dist/aos';
@import 'settings/_global.scss';
@import 'tools/_breakpoints.scss';
@import 'tools/_clearfix.scss';
@import 'tools/_columns.scss';
@import 'tools/_em.scss';
@import 'tools/_hidden.scss';
@import 'tools/_percentages.scss';
@import 'tools/_vw.scss';
@import 'generic/_box-sizing.scss';
@import 'generic/_normalize.scss';
@import 'generic/_reset.scss';
@import 'generic/_shared.scss';
@import 'elements/_blockquote.scss';
@import 'elements/_body.scss';
@import 'elements/_gradient-text.scss';
@import 'elements/_headings.scss';
@import 'elements/_hr.scss';
@import 'elements/_html.scss';
@import 'elements/_images.scss';
@import 'elements/_links.scss';
@import 'elements/_lists.scss';
@import 'elements/_tables.scss';
@import 'elements/_text.scss';
@import 'elements/_video.scss';

@import 'objects/_layout.scss';
@import 'objects/_list-bare.scss';
@import 'objects/_list-inline.scss';
@import 'objects/_media.scss';
@import 'objects/_table.scss';
@import 'objects/_wrapper.scss';
@import 'components/_accordions.scss';
@import 'components/_animation-bg.scss';
@import 'components/_arrow-default.scss';
@import 'components/_blogposts.scss';
@import 'components/_box-author.scss';
@import 'components/_box-person.scss';
@import 'components/_box-realization.scss';
@import 'components/_box-shadow.scss';
@import 'components/_breadcumbs.scss';
@import 'components/_btn.scss';
@import 'components/_comment-form.scss';
@import 'components/_comment.scss';
@import 'components/_desktop-nav.scss';
@import 'components/_email-copy.scss';
@import 'components/_featured-post.scss';
@import 'components/_footer-menu.scss';
@import 'components/_footer.scss';
@import 'components/_form.scss';
@import 'components/_header-button.scss';
@import 'components/_header-extra.scss';
@import 'components/_header.scss';
@import 'components/_language-switcher.scss';
@import 'components/_link-arrow.scss';
@import 'components/_link.scss';
@import 'components/_main-nav.scss';
@import 'components/_mega-menu.scss';
@import 'components/_pagination.scss';
@import 'components/_podcast-box.scss';
@import 'components/_podcasts.scss';
@import 'components/_popup.scss';
@import 'components/_post.scss';
@import 'components/_recaptcha.scss';
@import 'components/_related.scss';
@import 'components/_share-links.scss';
@import 'components/_social-list.scss';
@import 'components/_sub-heading.scss';
@import 'components/_subscribe-icons.scss';
@import 'components/_tag.scss';
@import 'components/_tease.scss';
@import 'components/_uniwersal-content.scss';
@import 'blocks/_aplication-box.scss';
@import 'blocks/_box-number.scss';
@import 'blocks/_cards-slide.scss';
@import 'blocks/_cards.scss';
@import 'blocks/_carousel-videos.scss';
@import 'blocks/_contact-content-form.scss';
@import 'blocks/_contact-form.scss';
@import 'blocks/_contact-no-form.scss';
@import 'blocks/_contact-photo.scss';
@import 'blocks/_container.scss';
@import 'blocks/_custom-list.scss';
@import 'blocks/_faq.scss';
@import 'blocks/_flip-cards.scss';
@import 'blocks/_full-image.scss';
@import 'blocks/_heading-featured.scss';
@import 'blocks/_heading-image.scss';
@import 'blocks/_heading-text-button.scss';
@import 'blocks/_hero.scss';
@import 'blocks/_logos.scss';
@import 'blocks/_media-text-button.scss';
@import 'blocks/_podcast-lists.scss';
@import 'blocks/_posts-slide.scss';
@import 'blocks/_realizations.scss';
@import 'blocks/_services.scss';
@import 'blocks/_simple-banner.scss';
@import 'blocks/_simple-centered-se.scss';
@import 'blocks/_simple-se.scss';
@import 'blocks/_spacer.scss';
@import 'blocks/_team.scss';
@import 'blocks/_testimonials.scss';
@import 'blocks/_text_links_image.scss';
@import 'blocks/_thank-you.scss';
@import 'blocks/_two-columns-text.scss';
@import 'blocks/_works-lists.scss';
@import 'utilities/_align.scss';
@import 'utilities/_clearfix.scss';
@import 'utilities/_display.scss';
@import 'utilities/_helpers.scss';
@import 'utilities/_hide.scss';
@import 'utilities/_position.scss';
@import 'layouts/_not-found-page.scss';
@import 'layouts/_podcasts-all.scss';
@import 'layouts/_realizations.scss';
@import 'layouts/_single-realization.scss';

// Placeholders before mp4 video
body {
    background-position: top;
    background-size: 100% auto;
    background-repeat: no-repeat;

    canvas {
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: -1;
        top: 0;
        left: 0;
    }
}

// .home {
// 	// background-image: url('~assets/images/home.png');
// 	background-image: url('~assets/images/bg-home-new-min.png');

// 	@include bp-down(medium) {
// 		background-image: url('~assets/images/bg-home-mobile-min.png');
// 	}
// }

// .page-id-37 {
// 	background-image: url('~assets/images/our_services.png');
// }

// .page-id-362 {
// 	background-image: url('~assets/images/service.png');
// }

// .page-id-29 {
// 	background-image: url('~assets/images/contact.png');
// }

// .page-id-27 {
// 	background-image: url('~assets/images/work.png');
// }

// .single-post {
// 	background-image: url('~assets/images/blogpost.png');
// }
