.l-realizations {
    font-style: normal;
    padding: 90px 0 120px 0;

    @include bp-down(medium) {
        padding: 64px 0;
    }

    &__title {
        margin-bottom: 40px;

        @include bp-down(medium) {
            margin-bottom: 20px;
        }
    }

    &__list {
        &-item {
            margin-bottom: 120px;

            @include bp-down(medium) {
                margin-bottom: 64px;
            }
        }
    }

    &__pagination-wrap {
        margin-top: 30px;

        @include bp-down(medium) {
            margin-top: 0;
        }
    }
}
