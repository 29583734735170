.c-custom-list {
    $self: &;

    margin-left: 30px;

    @include bp-down(medium) {
        margin-left: 30px;
        list-style-type: disc;
    }

    &__item {
        margin: 0 0 40px 0;
        font-style: normal;
        font-size: 18px;
        line-height: 1.67;

        &::marker {
            font-size: 8px;
        }

        @include bp-down(medium) {
            font-size: 16px;
            margin-bottom: 20px;
        }
    }

    p {
        margin-bottom: 0;
    }

    &--small-margin {
        #{$self}__item {
            margin-bottom: 2px;

            @include bp-down(medium) {
                margin-bottom: 2px;
            }
        }
    }

    &--type-violet {
        color: $color-violet-v2;
        #{$self}__item {
            color: $color-violet-v2;

            &-link {
                color: $color-violet-v2;
            }
        }
    }
}
