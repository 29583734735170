/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
    margin-right: auto;
    margin-left: auto;
    padding-left: #{$wrapper-gutter} + 'px';
    padding-right: #{$wrapper-gutter} + 'px';
    max-width: var(--wrapper, #{$wrapper} + 'px');
    width: 100%;

    @include bp-down(small) {
        padding-left: 16px;
        padding-right: 16px;
    }
}
