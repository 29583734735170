.c-featured-post {
    width: 100%;
    color: $color-brand-3;
    font-style: normal;
    // padding: 0 0 em(43) 0;
    padding-bottom: 184px;

    @include bp-down(medium) {
        padding-bottom: 128px;
    }

    &__content {
        width: 100%;

        @include bp(large) {
            padding: 0 em(54);
            width: 52%;
        }
    }

    &__title {
        margin-bottom: 24px;

        &-link {
            text-decoration: none;
        }

        @include bp-down(medium) {
            margin-bottom: 16px;
        }
    }

    &__desc {
        margin-bottom: 48px;

        @include bp-down(medium) {
            margin-bottom: 32px;
        }
    }

    a {
        margin: em(2.22) 0 0 0;
        display: inline-block;
        min-width: 161px;
    }

    &__tags {
        margin: 0 0 48px 0;
        padding: em(33) 0 0 0;
        gap: 8px;

        @include bp-down(medium) {
            margin: 0 0 32px 0;
            padding: 16px 0 0 0;
        }
    }

    &__thumb {
        width: 100%;
        border-radius: 8px;
        // margin-bottom: em(30);

        @include bp(large) {
            width: 48%;
            margin-bottom: 0;
        }

        img {
            object-fit: cover;
            width: 100%;
            max-height: 480px;
            border-radius: 8px;
        }
    }
}
