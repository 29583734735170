.c-share-links {
    &__social {
        margin: 3px 16px 3px 0;
        display: flex;
        border-radius: 50px;
        width: 40px;
        height: 40px;
        transition: $global-transition;
        background-color: #5c46bf;

        @include bp-down(large) {
            width: 32px;
            height: 32px;

            svg {
                width: 24px;
                height: 24px;
            }
        }

        &:last-child {
            margin: 3px 0 3px 0;
        }

        &--facebook {
            // background-color: #1877f2;
        }

        // &--twitter {
        // 	background-color: #1da1f2;
        // }

        // &--linkedin {
        // 	background-color: #2867b2;
        // }

        // &--youtube {
        // 	background-color: #f00;
        // }

        &:hover {
            opacity: 0.9;
        }
    }
}
