.b-testimonials {
    color: rgba($color-brand-3, 0.9);
    font-style: normal;
    font-weight: 400;
    text-align: center;

    $text-color: $color-custom-2;
    $list-padding: em(55);

    --text-color: #{$color-custom-2};
    --text-color-title: #{$color-black};
    --list-padding: #{$list-padding};
    --border-color: #{$color-brand-26};
    --border-color-section: #{$color-brand-12};
    --swiper-pagination-bullet-opacity: transparent;
    --swiper-pagination-color: transparent;
    --swiper-pagination-bullet-inactive-color: transparent;
    --swiper-pagination-bullet-width: 13px;
    --swiper-pagination-bullet-height: 13px;
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-bullet-horizontal-gap: 6.5px;
    --swiper-color: #{$color-brand-25};
    --swiper-color-active: #{$color-brand-22};
    --swiper-background-arrow: #{$color-brand-14};
    --swiper-background-arrow-hover: rgba(113, 86, 231, 0.5);
    --swiper-color-arrow: #{$color-brand-22};

    @include bp(large) {
        $list-padding-desktop: em(115);

        --list-padding: #{$list-padding-desktop};
    }

    &--dark {
        color: rgba($color-white, 0.9);
        $border-color-section: rgba($color-white, 0.25);
        // $text-color: rgba($color-white, 0.9);
        $text-color: $color-text-white;
        $list-padding: em(144);

        --text-color: #{$text-color};
        --text-color-title: #{$text-color};
        --list-padding: #{$list-padding};
        --border-color: #{$color-most-light-grey};
        --border-color-section: #{$border-color-section};
        --swiper-pagination-bullet-opacity: transparent;
        --swiper-pagination-color: transparent;
        --swiper-pagination-bullet-inactive-color: transparent;
        --swiper-color: #{$color-white};
        --swiper-color-active: #{$color-white};
        --swiper-background-arrow: rgba(255, 255, 255, 0.15);
        --swiper-background-arrow-hover: #{$color-brand-4};
        --swiper-color-arrow: #{$color-white};
    }

    &__wrapper {
        @include bp-down(small) {
            padding-left: calc(#{$wrapper-gutter + 'px'} / 2);
            padding-right: calc(#{$wrapper-gutter + 'px'} / 2);
        }

        // &::before {
        // 	content: '';
        // 	display: block;
        // 	width: 100%;
        // 	height: 1px;
        // 	top: 0;
        // 	background-color: var(--border-color-section);
        // }
    }

    &__list {
        padding: var(--list-padding) 0;

        @include bp(large) {
            padding: var(--list-padding) 0;
            max-width: 90%;
            margin: 0 auto;
        }
    }

    &__swiper {
        padding-bottom: 60px;
    }

    &__image {
        max-width: 141px;
        margin: 0 auto 20px auto;
        display: block;

        @include bp(large) {
            max-width: 184px;
            margin: 0 auto em(55) auto;
        }

        @include bp-down(small) {
            max-width: 100px;
        }
    }

    &__text {
        font-size: 20px;
        line-height: 1.4;
        // opacity: 0.9;
        margin: 0;
        color: var(--text-color);

        @include bp(large) {
            // font-size: em(36);
            max-width: 59.64%;
            width: calc(100% - 360px);
            margin: 0 auto;
            display: block;
        }

        @include bp-down(small) {
            font-size: 14px;
            line-height: 1.6;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 24px;
        margin: 0 0 12px;
        line-height: 1.25;
        // opacity: 0.9;
        color: var(--text-color-title);

        &::before {
            content: '';
            width: 64px;
            height: 2px;
            background-color: var(--border-color);
            display: block;
            margin: 22px auto;

            @include bp(large) {
                margin: 42px auto;
            }
        }

        @include bp-down(small) {
            font-size: 20px;
            font-weight: 500;
            line-height: 1.2;
        }

        // @include bp(large) {
        // 	font-size: em(39);
        // 	line-height: 1.44;
        // }
    }

    &__job {
        font-size: 18px;
        line-height: 1.6;
        margin: 0;
        // opacity: 0.9;
        color: var(--text-color);

        // @include bp(large) {
        // 	font-size: em(27);
        // 	line-height: 2.07;
        // }
        @include bp-down(small) {
            font-size: 16px;
            line-height: 1.6;
        }
    }

    &__next {
        right: 0;
    }

    &__prev {
        left: 0;
    }

    &__next,
    &__prev {
        --swiper-navigation-size: 58px;

        width: var(--swiper-navigation-size);
        // background: var(--swiper-background-arrow);
        // border-radius: 100px;
        transition: $global-transition;
        top: 72px;
        -webkit-tap-highlight-color: transparent;
        z-index: 98;

        @include bp-down(small) {
            top: auto;
            bottom: -15px;
        }

        &::after {
            content: none;
            display: none;
        }

        path {
            stroke: var(--swiper-color-arrow);
        }

        &:hover {
            svg path {
                stroke: var(--swiper-background-arrow-hover);
            }
        }

        &:focus,
        &:active {
            outline: none;
        }

        @include bp(small) {
            --swiper-navigation-size: 68px;
        }

        @include bp(large) {
            top: 50%;
            width: 94px;

            --swiper-navigation-size: 94px;
        }

        @include bp-down(small) {
            svg {
                width: 10px;
                height: auto;
            }
        }
    }

    &__pagination {
        display: flex;
        justify-content: center;
        align-items: center;

        .swiper-pagination-bullet {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border: 1px solid var(--swiper-color-active);
            background-color: transparent;
            transition: $global-transition;

            // &::before {
            // 	content: '';
            // 	width: 5px;
            // 	height: 5px;
            // 	display: block;
            // 	opacity: 1;
            // 	border-radius: 10px;
            // 	background-color: var(--swiper-color);
            // 	transition: $global-transition;
            // }

            &.swiper-pagination-bullet-active {
                background-color: var(--swiper-color-active);
                border-color: var(--swiper-color-active);

                // &::before {
                // 	content: '';
                // 	opacity: 1;
                // 	background-color: var(--swiper-color-active);
                // }
            }
        }
    }
}
