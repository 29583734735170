$root-class: '.b-realizations';

#{$root-class} {
    color: $color-black;
    overflow: hidden;
    font-style: normal;

    --tag-bg-color: #{$color-brand-14};
    --tag-color: #{$color-brand-22};
    --button-color: #{$color-black};
    --excerpt-color: #{$color-brand-24};

    &--dark {
        color: $color-custom-1;
        // --tag-bg-color: #{$color-brand-21};
        --tag-bg-color: #404040;
        // --tag-color: #{$color-white};
        --tag-color: $color-custom-1;
        --button-color: #{$color-white};
        --excerpt-color: #{$color-white};
    }

    &__list {
        position: relative;
        z-index: 2;
    }

    &__title-main {
        margin-bottom: 40px;

        @include bp-down(medium) {
            margin-bottom: 48px;
        }
    }

    &__desc {
        font-style: normal;
        font-weight: 600;
        color: $color-custom-1;
        font-size: 24px;
        line-height: 1.25;
        text-align: left;
        margin-bottom: 111px;
        z-index: 2;
        position: relative;
        padding: 0;
        max-width: 700px;

        @include bp-down(medium) {
            display: none;
        }
    }

    &__post {
        // margin: 0 0 em(70) 0;
        margin: 0 0 120px;

        @include bp-down(medium) {
            margin: 0 0 64px;
        }

        &.aos-animate {
            #{$root-class} {
                &__thumb--dark {
                    img {
                        opacity: 1;
                    }
                }
            }
        }
        // @include bp(medium) {
        // 	margin: 0 0 em(82) 0;
        // }

        // &:hover {
        // 	#{$root-class} {
        // 		&__thumb--dark {
        // 			img {
        // 				opacity: 1;
        // 			}
        // 		}
        // 	}
        // }
    }

    &__content {
        order: 1;
        width: 100%;
        padding-top: 40px;

        @include bp-down(medium) {
            padding-top: 0;
        }

        @include bp(medium) {
            width: 50%;
        }

        &--even {
            @include bp(medium) {
                padding-right: 70px;
                padding-left: 70px;
            }

            @include bp(full) {
                padding-right: 140px;
            }

            @include bp-down(large) {
                padding-left: 0;
            }
        }

        &--odd {
            @include bp(medium) {
                padding-left: 70px;
                padding-right: 70px;
            }

            @include bp(full) {
                padding-left: 140px;
            }

            @include bp-down(large) {
                padding-right: 0;
            }
        }
    }

    &__thumb {
        width: 100%;
        margin: 0 0 30px 0;
        border-radius: 8px;
        background-color: $color-brand-3;

        @include bp(medium) {
            width: 50%;
            margin: 0;
        }

        @include bp-down(medium) {
            margin: 0 0 18px 0;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: opacity 0.6s linear;
            border-radius: 8px;

            &.aos-animate {
                opacity: 1;
            }

            @include bp-down(medium) {
                max-height: em(542);
            }
        }

        &--even {
            @include bp(medium) {
                order: 2;
            }
        }

        &--dark {
            img {
                opacity: 0.4;

                @include bp-down(medium) {
                    opacity: 1;
                }
            }
        }
    }

    &__tags {
        margin: 0 0 48px 0;
        gap: 8px;

        @include bp-down(medium) {
            margin: 0 0 32px 0;
        }
    }

    &__tag {
        background: var(--tag-bg-color);
        border-radius: 50px;
        font-weight: 400;
        // font-size: em(17);
        font-size: 14px;
        color: var(--tag-color);
        line-height: 1.4;
        padding: 4px 12px;
        letter-spacing: 0.5px;
        text-transform: capitalize;
    }

    &__available-later {
        display: block;
        margin-top: 48px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.25;
        letter-spacing: 0.5px;

        @include bp-down(medium) {
            margin-top: 32px;
        }
    }

    &__title {
        font-weight: 600;
        // font-size: em(36);
        font-size: 40px;
        line-height: 1.2;
        margin: 0 0 24px 0;

        &-link {
            text-decoration: none;
            color: inherit;
        }

        @include bp-down(medium) {
            font-size: 28px;
            line-height: 1.14;
            margin: 0 0 16px 0;
        }
        // @include bp(medium) {
        // 	font-size: em(60);
        // 	line-height: 1.37;
        // 	margin: 0 0 24px 0;
        // }
    }

    &__excerpt {
        font-weight: 400;
        // font-size: em(22);
        font-size: 18px;
        line-height: 1.67;
        margin: 0 0 48px 0;
        color: var(--excerpt-color);

        @include bp-down(medium) {
            margin: 0 0 32px 0;
            font-size: 16px;
        }
        // @include bp(medium) {
        // 	font-size: em(26);
        // 	line-height: 1.54;
        // 	margin: 0 0 24px 0;
        // }
    }

    &__button {
        margin: 42px 0 0 0;

        @include bp-down(medium) {
            margin: 32px 0 0 0;
        }

        @include bp(medium) {
            margin: 82px 0 0 0;
        }

        a {
            opacity: 0.9;
        }
    }
}
