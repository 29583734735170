.c-arrows-default {
    padding-top: 48px;
    text-align: center;
    margin-left: -35px;

    &--dark {
        svg path {
            stroke: $color-black-v2;
        }
    }

    @include bp-down(medium) {
        margin-left: -16px;
    }

    &__arrow {
        position: relative;
        background-color: transparent;
        margin: 0;
        border: none;
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;

        &:focus {
            display: inline-block;
            outline: none;
        }

        &--left {
            margin-right: 50px;
        }

        &--right {
            margin-left: 50px;
        }

        svg path {
            transition: $global-transition;
        }

        &:hover {
            svg path {
                stroke: $color-brand-4;
            }
        }

        &.swiper-button-disabled {
            opacity: 0.1;
        }
    }
}
