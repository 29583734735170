.c-desktop-nav {
    --color-link: #{$color-black-v2};
    --color-link-hover: #{$color-primary-600};
    --bg-color-child-items: #{$color-custom-1};
    --color-child-link: #{$color-black-v2};

    &--light {
        --color-link: #{$color-custom-1};
        --color-link-hover: #{$color-brand-4};
        --bg-color-child-items: #{$color-black-v2};
        --color-child-link: #{$color-custom-1};
    }

    &__items {
        margin: 0;
        padding: 0;
        list-style: none;
        color: var(--color-link);
    }

    &__link {
        text-decoration: none;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5;
        padding: 0 16px;
        color: currentColor;

        svg {
            transition: transform 300ms ease-in-out;

            path {
                fill: currentColor;
            }
        }
    }

    &__item {
        margin: 0;
        padding: 8px 0;

        &:hover {
            > a {
                color: var(--color-link-hover);

                svg {
                    transform: rotate(180deg);
                }
            }

            a + ul {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    &__child {
        &-items {
            visibility: hidden;
            opacity: 0;
            transition: $global-transition;
            list-style: none;
            margin: 8px 0 0 0;
            padding: 16px;
            gap: 16px;
            background-color: var(--bg-color-child-items);
            position: absolute;
            top: auto;
        }

        &-item {
            margin: 0;
            padding: 0;
        }

        &-link {
            text-decoration: none;
            color: var(--color-child-link);
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 1.5;

            &:hover {
                color: var(--color-link-hover);
            }
        }

        &-featured {
            margin-top: 9px;
            margin-bottom: 9px;

            --c-link-arrow-style-dark-color: var(--color-child-link);
            --c-link-arrow-style-dark-color-hover: var(--color-link-hover);
        }
    }
}
